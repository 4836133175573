import { useEffect } from "react";
import { format } from "date-fns";

import { useWalkThroughModalStore } from "./WalkThroughModal";
import { TableData } from "@/types/table.types";
import Icons from "@/components/Icons";
import TableIcon from "@/assets/svgs/tableIcon2.svg";

interface Props {
  selectedTable: Partial<TableData> | undefined;
  setSelectedTable: (table: Partial<TableData>) => void;
  autoPilotTables: Partial<TableData>[] | undefined;
  title: string;
  flowName: string;
}

function AutoPilotSidebar({
  selectedTable,
  setSelectedTable,
  autoPilotTables,
  title,
  flowName,
}: Props) {
  const { updateModalState } = useWalkThroughModalStore();

  useEffect(() => {
    if (autoPilotTables && autoPilotTables.length) {
      const tableId = selectedTable?._id || autoPilotTables[0]?._id;
      setSelectedTable(
        autoPilotTables.find((item) => item._id === tableId) || {},
      );
    }
  }, [autoPilotTables]);

  return (
    <div className="border-r border-[#D0D4DC] font-poppins">
      <div className="px-4 py-6 border-b border-[#F0EFF1]">
        <h3 className="text-[1.3rem] font-semibold">Autopilot</h3>
        <p className="text-sm">{title}</p>
      </div>
      <div className="px-4 py-6 border-b border-[#F0EFF1] space-y-3">
        <button
          onClick={() => {
            updateModalState({
              isOpen: true,
              title: title,
              flowName: flowName,
              action: "new",
            });
          }}
          className="text-sm font-medium flex items-center space-x-2.5 border border-[#DDD8FF] bg-[#FDFCFF] rounded-lg py-2 px-4 w-full"
        >
          <span className="flex-shrink-0 w-4 h-4 rounded-full bg-[#F5F3FF] before:content-[''] relative block before:absolute before:w-full before:h-[1.6px] before:bg-[#090909] before:top-[calc(50%_-_0.6px)] before:-translate-x-1/2 after:content-[''] after:absolute after:h-full after:w-[1.6px] after:bg-[#090909] after:left-1/2 after:-translate-x-1/2"></span>
          <span>Create New Workflow</span>
        </button>
        {selectedTable?._id && (
          <button
            className="text-sm font-medium flex items-center space-x-2.5 border border-[#DDD8FF] bg-[#FDFCFF] rounded-lg py-2 px-4 w-full"
            onClick={() => {
              updateModalState({
                isOpen: true,
                title: title,
                flowName: flowName,
                action: "update",
                selectedTableData: selectedTable,
              });
            }}
            disabled={!selectedTable?._id}
          >
            <Icons.SettingsIcon className="w-4 h-auto" />
            <span>Edit this workflow</span>
          </button>
        )}
      </div>
      <div className="">
        <h3 className="text-base font-medium border-b border-[#F0EFF1] py-4 px-4">
          Your Tables
        </h3>
        <div className="min-h-[35vh] border-b border-[#F0EFF1]">
          {autoPilotTables?.map((table, index) => {
            const formattedDate = format(
              new Date(table?.updatedAt || ""),
              "EE, MMM d, yy",
            );

            return (
              <div
                key={`${table?._id}-${index}`}
                className={`flex items-center space-x-4 px-4 py-4 hover:bg-[#F5F3FF] duration-200 cursor-pointer border-b border-[#F0EFF1] ${
                  selectedTable?._id === table?._id ? "bg-[#F5F3FF]" : ""
                }`}
                onClick={() => setSelectedTable(table)}
              >
                <img src={TableIcon} alt="Table Icon" className="w-5 h-auto" />
                <div className="w-full overflow-hidden">
                  <p className="text-base font-medium truncate">
                    {table?.name}
                  </p>
                  <p className="text-sm">
                    Last updated:{" "}
                    <span className="text-[#7E889F]">{formattedDate}</span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default AutoPilotSidebar;
