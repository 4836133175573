import { useMutation } from "@tanstack/react-query";

import leadService from "@/services/leads.service";
import { useSearchLeadsStore } from "@/components/PeopleSearch/store.ts";

import { SearchPeopleType } from "@/types/leads.types";

export const usePeopleSearch = () => {
  const updateState = useSearchLeadsStore((state) => state.updateState);
  return useMutation({
    mutationFn: ({
      payload,
      isUseMixrank,
    }: {
      payload: SearchPeopleType;
      isUseMixrank?: boolean;
    }) =>
      leadService.searchPeople(
        payload,
        `leads/search-people-2${isUseMixrank ? "?isUseMixrank=true" : ""}`,
      ),
    onSuccess: (response, { isUseMixrank }) => {
      const newState = {
        searchLeadsResult: {
          profiles: response?.data.profiles || [],
          pagination: response?.data.pagination || {
            total_entries: 0,
            total_pages: 0,
            page: 1,
            per_page: 100,
          },
          cacheKey: response?.data.cacheKey || "",
          apiPath: `leads/search-people-2?per_page=100&want_to_import=true${
            isUseMixrank ? "&isUseMixrank=true" : ""
          }`,
        },
        naturalFilters: response?.data.naturalFilters,
        ...((response?.data?.pagination?.page || 0) <= 1
          ? {
              selectedLeads: [],
              selectionCount: 0,
              selectionAction: "clear-all",
            }
          : {}),
        expandedJobTitles: response?.data.expandedJobTitles || {},
        isSearching: false,
        searchingFor: "",
        isUseMixrank,
      } as any;
      updateState({ ...newState });
    },
    onError: (error) => {
      console.log(error, "error");
      updateState({
        isSearching: false,
        searchingFor: "",
        isShowErrorModal: error?.message?.includes("Upgrade"),
      });
    },
  });
};
