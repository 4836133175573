import { useTableList } from "@/hooks/useTableList";
import { TableData } from "@/types/table.types";
import { Controls, MarkerType, ReactFlow, Node } from "@xyflow/react";
import React, { useEffect, useRef, useState } from "react";
import AutoPilotSidebar from "../Common/AutoPilot/Sidebar";
import { createSearchLeadsStore, TabContext } from "../PeopleSearch/store";
import SourceNode from "./SourceNode";
import PersanaTable from "../Common/AutoPilot/PersanaTable/PersanaTable";

const AUTO_PILOT_NODE_ID = {
  JOB_OPENING_SOURCE: "jobOpeningSource",
  JOB_OPENINGS_TABLE: "jobOpeningsTable",
};

const activeColor = "#078A52";

const activeStrokeStyle = {
  strokeWidth: 2,
  stroke: activeColor,
};

const INITIAL_TOOLBAR_STATE = {
  [AUTO_PILOT_NODE_ID.JOB_OPENING_SOURCE]: false,
  [AUTO_PILOT_NODE_ID.JOB_OPENINGS_TABLE]: false,
};

function AutoPilotLeadmagicWorkflow() {
  const [selectedTable, setSelectedTable] = useState<Partial<TableData>>({});

  const { data: allTables, refetch } = useTableList();
  const autoPilotJobOpeningsTables = allTables?.filter(
    (item) => item.workflowType === "leadmagic-job-openings",
  );
  const isConfigured = !!selectedTable?._id;
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState([
    {
      id: "e1-2",
      source: AUTO_PILOT_NODE_ID.JOB_OPENING_SOURCE,
      target: AUTO_PILOT_NODE_ID.JOB_OPENINGS_TABLE,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
      },
    },
  ]);
  const [toolbarState, setToolbarState] = useState(INITIAL_TOOLBAR_STATE);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleNodeClick = (e: React.MouseEvent, node: Node) => {
    e.stopPropagation();
    setToolbarState(() => ({
      ...INITIAL_TOOLBAR_STATE,
      [node.id]: true,
    }));
  };

  const handleCanvasClick = () => {
    setToolbarState(INITIAL_TOOLBAR_STATE);
  };

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const nodeWidth = 100;
      const topMargin = 150;

      const startX = (containerWidth - nodeWidth) / 2.5;

      const updatedNodes: Node[] = [
        {
          id: AUTO_PILOT_NODE_ID.JOB_OPENING_SOURCE,
          type: "customA",
          position: { x: startX, y: topMargin },
          data: {
            showToolbar: !isConfigured
              ? true
              : toolbarState[AUTO_PILOT_NODE_ID.JOB_OPENING_SOURCE],
            isConfigured,
            tableData: selectedTable,
            refetch,
          },
        },
        {
          id: AUTO_PILOT_NODE_ID.JOB_OPENINGS_TABLE,
          type: "customB",
          position: { x: startX, y: topMargin + 200 },
          data: {
            tableTitle: "Job Openings Table",
            showToolbar: toolbarState[AUTO_PILOT_NODE_ID.JOB_OPENINGS_TABLE],
            isDisabled: !isConfigured,
            tableData: selectedTable,
          },
        },
      ];

      setNodes(updatedNodes);

      const updatedEdges = edges.map((edge) => {
        if (
          edge.source === AUTO_PILOT_NODE_ID.JOB_OPENING_SOURCE &&
          isConfigured
        ) {
          return {
            ...edge,
            markerEnd: {
              ...edge.markerEnd,
              color: activeColor,
            },
            style: {
              ...activeStrokeStyle,
            },
          };
        }
        return edge;
      });

      setEdges(updatedEdges);
    }
  }, [toolbarState, selectedTable]);

  useEffect(() => {
    if (
      autoPilotJobOpeningsTables &&
      autoPilotJobOpeningsTables.length &&
      !selectedTable?._id
    ) {
      setSelectedTable(autoPilotJobOpeningsTables[0]);
    }
  }, [allTables, autoPilotJobOpeningsTables]);

  return (
    <div
      className="grid min-h-screen"
      style={{
        gridTemplateColumns: "repeat(1, 25% 1fr)",
      }}
    >
      <AutoPilotSidebar
        title="Job Openings with Leadmagic"
        flowName="leadmagic_job_openings"
        selectedTable={selectedTable}
        setSelectedTable={setSelectedTable}
        autoPilotTables={autoPilotJobOpeningsTables}
      />
      <div
        ref={containerRef}
        style={{ width: "calc(100vw - 80px)" }}
        className="h-screen"
        onClick={handleCanvasClick}
      >
        {selectedTable?._id && (
          <TabContext.Provider value={createSearchLeadsStore("autopilot_1")}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={{
                customA: SourceNode,
                customB: PersanaTable,
              }}
              onNodeClick={handleNodeClick}
            >
              <Controls />
            </ReactFlow>
          </TabContext.Provider>
        )}
      </div>
    </div>
  );
}

export default AutoPilotLeadmagicWorkflow;
