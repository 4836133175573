import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import { useMiscData } from "@/hooks/useMisc";

interface Props {
  value: Option[];
  onChange: (selectedValues: Option[]) => void;
}

const CompanyTypes = ({ value, onChange }: Props) => {
  const { data, isLoading } = useMiscData<Option[]>("company-types");

  const options = data?.data || [];

  const handleChange = (selected: Option | Option[]) => {
    const selectedValues = Array.isArray(selected) ? selected : [selected];
    onChange(selectedValues);
  };

  return (
    <>
      <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">Company Type</p>
      <MultiSelectDropdown
        placeholder="e.g. Privately Held"
        options={options}
        onChange={handleChange}
        isMulti
        value={value}
        isLoading={isLoading}
        isCreateAble={false}
      />
    </>
  );
};

export default CompanyTypes;
