export const messages = [
  {
    slug: "personalized-emails-linkedin-profiles",
    title: "Personalized Emails from LinkedIn Profiles",
    message:
      "Add a row to the bottom with a LinkedIn URL to generate personalized first lines and find valid emails.",
    description:
      "Generate personalized first lines and find valid emails using LinkedIn URLs.",
    templateId: "12",
  },
  {
    slug: "personalized-emails-company-news",
    title: "Personalized Emails from Company News",
    message:
      "Add a row to the bottom with a LinkedIn URL and Company Website to generate personalization based on news and find valid emails.",
    description:
      "Generate personalized first lines based on recent company news and find valid emails.",
    templateId: "13",
  },
  {
    slug: "find-work-emails",
    title: "Find Work Emails (Name + Company Website)",
    message:
      "Add a row to the bottom with a Name, LinkedIn URL, and Company Website to find valid emails.",
    description: "Find valid emails using a name and company website.",
    templateId: "3",
  },
  {
    slug: "find-linkedin-urls-work-emails",
    title: "Find LinkedIn URLs from Work Emails",
    message:
      "Add a row to the bottom with a Work Email to find the associated LinkedIn URL.",
    description: "Identify LinkedIn URLs based on work email addresses.",
    templateId: "2",
  },
  {
    slug: "track-companies-hiring",
    title: "Track Companies Hiring",
    message: "Add a row to the bottom with a Company Name hiring trends.",
    description:
      "Monitor a list of companies for job openings to identify potential opportunities.",
    templateId: "4",
  },
  {
    slug: "find-company-news",
    title: "Find Company News",
    message:
      "Add a row to the bottom with a Company URL to find relevant news.",
    description:
      "Discover news related to specific companies using their URLs.",
    templateId: "5",
  },
  {
    slug: "find-company-pricing",
    title: "Find Company Pricing",
    message:
      "Add a row to the bottom with a Company URL to find pricing details.",
    description:
      "Retrieve pricing details for specific companies using Persana’s Quantum Agent.",
    templateId: "6",
  },
  {
    slug: "find-company-revenue",
    title: "Find Company Revenue",
    message:
      "Add a row to the bottom with a Company Name to retrieve revenue data.",
    description: "Estimate company revenue using advanced tools.",
    templateId: "7",
  },
  {
    slug: "find-company-competitors",
    title: "Find Company Competitors",
    message:
      "Add a row to the bottom with a Company URL to find its top competitors.",
    description:
      "Identify competitors and differentiate their strategies using Persana’s Quantum Agent.",
    templateId: "8",
  },
  {
    slug: "find-website-traffic",
    title: "Find Website Traffic",
    message:
      "Add a row to the bottom with a Website URL to analyze traffic and insights.",
    description: "Explore website traffic data and other insights.",
    templateId: "9",
  },
  {
    slug: "podcast-appearance-suggestions",
    title: "Podcast Appearance Suggestions",
    message:
      "Add a row to the bottom with a Person Name or their Company URL to generate outreach ideas and find appearance opportunities.",
    description:
      "Discover podcasts and craft personalized first lines for outreach.",
    templateId: "10",
  },
  {
    slug: "company-personalization",
    title: "Company Personalization",
    message:
      "Add a row to the bottom with a Company Website and generate a personalized output to use in your first line of outreach.",
    description:
      "Enhance company details using enrichment tools and AI agents.",
    templateId: "11",
  },
];
