import { twMerge } from "tailwind-merge";

import InfoIcon from "@/assets/svgs/InfoIcon.svg";

function WalkThroughInfo({
  info,
  className,
}: {
  info: string;
  className: string;
}) {
  return (
    <div
      className={twMerge(
        "text-[#373F51] font-poppins font-semibold flex items-center space-x-2 px-4",
        className,
      )}
    >
      <img src={InfoIcon} className="" />
      <p>{info}</p>
    </div>
  );
}

export default WalkThroughInfo;
