import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import Icons from "@/components/Icons";
import leadService from "@/services/leads.service";
import { Button, Text, useToast, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { frequencyList } from "./Configurations";
import Sidebar from "@/components/PeopleSearch/Sidebar";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import {
  WalkThroughModalActionType,
  WalkThroughModalBody,
  WalkThroughModalFooter,
} from "@/components/Common/AutoPilot/WalkThroughModal";
import {
  createSearchLeadsStore,
  TabContext,
  useSearchLeadsStore,
} from "@/components/PeopleSearch/store";
import { convertSearchPeoplePayload, filterValidObjKeys } from "@/utils";
import tableService from "@/services/table.service";
import { userStore } from "@/stores/user.store";
import WalkThroughInfo from "@/components/Common/AutoPilot/WalkThroughModal/WalkThroughInfo";
import { useQueryClient } from "@tanstack/react-query";
import { TableData } from "@/types/table.types";

interface FindPeopleSourceProps {
  totalSteps: number;
  action: WalkThroughModalActionType;
  selectedTableData?: Partial<TableData> | undefined;
}

function FindPeopleSourceComponent({
  totalSteps,
  action,
  selectedTableData,
}: FindPeopleSourceProps) {
  const [isTriggerLoading, setIsTriggerLoading] = useState(false);
  const [frequencyToUpdate, setFrequencyToUpdate] = useState<Option | null>(
    null,
  );

  const toast = useToast();
  const queryClient = useQueryClient();

  const updateLeadState = useSearchLeadsStore((state) => state.updateState);
  const currentSearchPayload = useSearchLeadsStore(
    (state) => state.sideBarInput,
  );
  const currentUser = userStore((state) => state.currentUser);

  const isConfigured = !!selectedTableData;
  const maxLeads =
    currentUser?.planType?.toLocaleLowerCase() === "free" ? 25 : 1000;

  const handleTriggerFlow = async () => {
    setIsTriggerLoading(true);
    await leadService.runAutoPilot(
      undefined,
      "update-auto-pilot-table",
      selectedTableData?._id || "",
    );
    window.open(`/table/${selectedTableData?._id}`, "_blank");
    setIsTriggerLoading(false);
  };

  const updatePreferences = async () => {
    setIsTriggerLoading(true);
    const convertedPayload = convertSearchPeoplePayload(currentSearchPayload);
    const validInputtedData = filterValidObjKeys(convertedPayload);
    const response = await tableService.updateTableDataV2(
      selectedTableData?._id || "",
      {
        metaData: {
          ...validInputtedData,
        },
      },
    );
    if (response.success) {
      toast({
        title: "Success",
        description: "Successfully updated the preferences.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      handleTriggerFlow();
    } else {
      toast({
        title: "Error",
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    setIsTriggerLoading(false);
  };

  const handleAutoPilot = async () => {
    setIsTriggerLoading(true);
    const convertedPayload = convertSearchPeoplePayload(currentSearchPayload);
    const validInputtedData = filterValidObjKeys(convertedPayload);
    const payload = {
      ...validInputtedData,
      frequencyToUpdate: frequencyToUpdate?.value,
      isForFindEmail: false,
      selectedLeads: [],
      customSelectionNumber: 0,
      isSelectAll: true,
      isForAutoPilot: true,
      totalEntries: maxLeads,
    };
    const response = await leadService.runAutoPilot(
      payload,
      "create-auto-pilot-table",
    );
    window.open(`/table/${response?.data?.tableData?._id}`, "_blank");
    queryClient.invalidateQueries({
      queryKey: ["tables"],
    });
    setIsTriggerLoading(false);
  };

  useEffect(() => {
    if (selectedTableData?.metaData && action === "update") {
      const freq = frequencyList?.find(
        (item) => item.value === selectedTableData?.metaData?.frequencyToUpdate,
      ) as Option;

      setFrequencyToUpdate(freq);

      updateLeadState({
        peopleSearchPayload: selectedTableData?.metaData || null,
      });
    }
  }, [selectedTableData]);

  return (
    <>
      <WalkThroughModalBody>
        <WalkThroughInfo
          info="Find new leads that fit your Ideal Customer Profile (ICP)."
          className="py-4 border-[#F0EFF1] border-b-[1.5px]"
        />
        <div className="h-full p-4">
          <VStack spacing={4} align="stretch" marginBottom={4}>
            {isConfigured && action === "update" ? (
              <Button
                colorScheme="purple"
                variant={"outline"}
                leftIcon={<Icons.RefreshIcon />}
                size={"sm"}
                className="w-fit"
                onClick={handleTriggerFlow}
                isLoading={isTriggerLoading}
              >
                Trigger Flow
              </Button>
            ) : null}

            <Text fontSize="md" fontWeight="semibold">
              How frequently you want to fetch the new leads?
            </Text>
            <MultiSelectDropdown
              isMulti={false}
              placeholder="Frequency to update the engagement data"
              closeMenuOnSelect
              options={frequencyList}
              value={frequencyToUpdate}
              onChange={(selected) => setFrequencyToUpdate(selected as Option)}
            />
          </VStack>
          <Sidebar isForAutoPilot />
        </div>
      </WalkThroughModalBody>
      <WalkThroughModalFooter
        isLoading={isTriggerLoading}
        onSave={async () => {
          action === "new"
            ? await handleAutoPilot()
            : await updatePreferences();
        }}
        totalSteps={totalSteps}
      />
    </>
  );
}

const FindPeopleSource: React.FC<FindPeopleSourceProps> = (props) => {
  return (
    <TabContext.Provider value={createSearchLeadsStore("autopilot_1")}>
      <FindPeopleSourceComponent {...props} />
    </TabContext.Provider>
  );
};

export default FindPeopleSource;
