import { useNavigate } from "@tanstack/react-router";
import Icons from "../Icons";
import { allTemplates } from "../Leads/UseTemplateModal/constants";
import FindLeadsIcon from "@/assets/svgs/auto-pilot-find-leads.svg";

const PlaybooksSection = () => {
  const navigate = useNavigate();
  return (
    <div className="!min-h-[520px] rounded-[8px] border border-[#F0EFF1] px-[32px] py-[16px]">
      <div className="mb-8 flex items-center justify-between">
        <p className="text-[14px] font-medium">Playbooks</p>
        <p
          className="flex cursor-pointer items-center gap-2 text-[14px] font-medium text-[#5024DC] hover:underline"
          onClick={() => navigate({ to: "/playbooks" })}
        >
          See all
          <span>
            <Icons.ChevronRightIcon />
          </span>
        </p>
      </div>
      <div className="flex flex-col gap-4">
        {allTemplates?.slice(0, 4).map((item) => (
          <div
            className="flex h-[90px] cursor-pointer items-center gap-3 rounded-[12px] border border-[#D0D4DC] bg-[#FDFCFF] p-[8px]"
            onClick={() =>
              navigate({
                to: "/playbooks/$templateId",
                params: {
                  templateId: item.heading,
                },
              })
            }
          >
            <img src={FindLeadsIcon} />
            <div>
              <p className="text-[14px] font-medium text-[#0F0D1C]">
                {item.heading}
              </p>
              <p className="text-[12px] text-[#7E889F]">{item.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlaybooksSection;
