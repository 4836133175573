import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { TableData } from "@/types/table.types";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import leadService from "@/services/leads.service";
import { queryClient } from "@/lib/queryClient";
import tableService from "@/services/table.service";
import Icons from "@/components/Icons";

type Props = {
  isConfigured: boolean;
  isOpen: boolean;
  tableData?: TableData;
  onClose: (isRefetch: boolean) => void;
};
const detailsPreferenceList = [
  {
    label: "Post Likes",
    value: "likes",
  },
  {
    label: "Post Comments",
    value: "comments",
  },
];
const frequencyList = [
  {
    label: "Daily",
    value: "1",
  },
  {
    label: "Weekly",
    value: "7",
  },
  {
    label: "Biweekly",
    value: "15",
  },
  {
    label: "Monthly",
    value: "30",
  },
];

const Configurations = ({
  isConfigured,
  isOpen,
  tableData,
  onClose,
}: Props) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [frequencyToUpdate, setFrequencyToUpdate] = useState<Option | null>(
    null,
  );
  const [postDetailsPreference, setPostDetailsPreference] = useState<
    Option[] | null
  >(null);

  const handleAutoPilot = async () => {
    setIsLoading(true);
    const response = await leadService.runPostEngagements(
      {
        linkedinUrl,
        frequencyToUpdate: frequencyToUpdate?.value,
        postDetailsPreference: postDetailsPreference?.map((item) => item.value),
      },
      "create-post-engagement-table",
    );
    queryClient.refetchQueries({
      queryKey: ["tables"],
    });
    window.open(`/table/${response?.data?.tableData?._id}`, "_blank");
    setIsLoading(false);
    onClose(true);
  };

  const handleTriggerFlow = async () => {
    setIsLoading(true);
    await leadService.runPostEngagements(
      {
        tableId: tableData?._id || "",
      },
      "update-post-engagement-table",
    );
    window.open(`/table/${tableData?._id}`, "_blank");
    onClose(true);
    setIsLoading(false);
  };

  const updatePreferences = async () => {
    setIsLoading(true);
    const response = await tableService.updateTableDataV2(
      tableData?._id || "",
      {
        metaData: {
          linkedinUrl,
          frequencyToUpdate: frequencyToUpdate?.value,
          postDetailsPreference: postDetailsPreference?.map(
            (item) => item.value,
          ),
        },
      },
    );
    if (response.success) {
      toast({
        title: "Success",
        description: "Successfully updated the preferences.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      if (linkedinUrl !== tableData?.metaData?.linkedinUrl) {
        handleTriggerFlow();
      }
    } else {
      toast({
        title: "Error",
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    onClose(true);
    setIsLoading(false);
  };

  useEffect(() => {
    const freq = frequencyList?.find(
      (item) => item.value === tableData?.metaData?.frequencyToUpdate,
    ) as Option;
    const pref = detailsPreferenceList?.filter((item) =>
      tableData?.metaData?.postDetailsPreference?.includes(item.value),
    );

    setLinkedinUrl(tableData?.metaData?.linkedinUrl || "");
    setFrequencyToUpdate(freq);
    setPostDetailsPreference(pref);
  }, [tableData]);

  return (
    <Modal
      size={"5xl"}
      isOpen={isOpen}
      onClose={() => onClose(false)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <p>Autopilot - Post Engagement Preferences</p>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="custom-scrollbar max-h-[500px] min-h-[500px] overflow-y-auto">
          <VStack spacing={8} align={"stretch"} mt={4}>
            {isConfigured && (
              <Button
                colorScheme="purple"
                variant={"outline"}
                leftIcon={<Icons.RefreshIcon />}
                size={"sm"}
                className="w-fit"
                onClick={handleTriggerFlow}
                isLoading={isLoading}
              >
                Trigger Flow
              </Button>
            )}

            <VStack spacing={2} align="stretch">
              <Text fontSize="md" fontWeight="semibold">
                Company / Person Linkedin URL
              </Text>
              <Input
                placeholder="Enter company / Person linkedIn url"
                size="md"
                variant="outline"
                value={linkedinUrl}
                onChange={(e) => setLinkedinUrl(e.target.value)}
                borderRadius="md"
                focusBorderColor="blue.400"
              />
            </VStack>
            <VStack spacing={2} align="stretch">
              <Text fontSize="md" fontWeight="semibold">
                How frequently you want to update the post engagement data?
              </Text>
              <MultiSelectDropdown
                isMulti={false}
                placeholder="Frequency to update the engagement data"
                closeMenuOnSelect
                options={frequencyList}
                value={frequencyToUpdate}
                onChange={(selected) =>
                  setFrequencyToUpdate(selected as Option)
                }
              />
            </VStack>
            <VStack spacing={2} align="stretch">
              <Text fontSize="md" fontWeight="semibold">
                Select your preferences
              </Text>
              <MultiSelectDropdown
                isMulti={true}
                placeholder="Select an item from the dropdown..."
                options={detailsPreferenceList}
                value={postDetailsPreference}
                onChange={(value) => {
                  setPostDetailsPreference(value as Option[]);
                }}
                isCreateAble={false}
              />
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            size={"sm"}
            mr={3}
            onClick={() => onClose(false)}
          >
            Close
          </Button>
          <Button
            colorScheme="blue"
            variant="outline"
            size={"sm"}
            isLoading={isLoading}
            isDisabled={
              !linkedinUrl ||
              !frequencyToUpdate?.value ||
              !postDetailsPreference?.length
            }
            onClick={() =>
              isConfigured ? updatePreferences() : handleAutoPilot()
            }
          >
            {isConfigured ? "Update" : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Configurations;
