import { useSearchLeadsStore } from "@/stores/searchleads.store";
import { InputtedData } from "../store";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";

interface Props {
  inputtedData: InputtedData;
  onClick: (target: Option | Option[]) => void;
}

const Educations = ({ inputtedData, onClick }: Props) => {
  const id = useSearchLeadsStore((state) => state.instanceId);
  const genId = (field: string) => `${id}-${field}`;
  return (
    <div id={genId("educations")}>
      <p className="sticky mb-2 text-[14px] font-[600] text-[#1a202c]">
        Educations
      </p>
      <MultiSelectDropdown
        placeholder="e.g. Harvard University"
        options={[]}
        onChange={onClick}
        isCreateAble
        value={inputtedData?.educations}
      />
    </div>
  );
};

export default Educations;
