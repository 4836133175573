import React from "react";
import MultiSelectDropdown from "../Common/MultiSelectDropdown";

interface Option {
  label: string;
  value: string;
}

const options: Option[] = [
  { label: "0.1M", value: "100000" },
  { label: "0.5M", value: "500000" },
  { label: "1M", value: "1000000" },
  { label: "5M", value: "5000000" },
  { label: "10M", value: "10000000" },
  { label: "25M", value: "25000000" },
  { label: "50M", value: "50000000" },
  { label: "100M", value: "100000000" },
  { label: "500M", value: "500000000" },
  { label: "1B", value: "1000000000" },
  { label: "5B", value: "5000000000" },
  { label: "10B", value: "10000000000" },
];

interface Props {
  value: Option;
  onChange: (value: Option | Option[]) => void;
}

const Revenue: React.FC<Props> = ({ value, onChange }) => {
  return (
    <MultiSelectDropdown
      placeholder="e.g. 1M"
      options={options}
      onChange={onChange}
      isMulti={false}
      value={value}
      isCreateAble={false}
      closeMenuOnSelect
    />
  );
};

export default Revenue;
