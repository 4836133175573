/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useCallback } from "react";
import debounce from "lodash/debounce";
import DataTable from "react-data-table-component";
import {
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";

import Icons from "@/components/Icons";
import userService from "@/services/user.service";
import { userStore } from "@/stores/user.store";

const UserLogin = () => {
  const toast = useToast({
    position: "top-right",
  });

  const [user, setUser] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setcurrentPage] = useState(1);
  const [searchtext, setSearchtext] = useState("");
  const [loading, setLoading] = useState(false);
  const { currentUser } = userStore();

  const customStyles = {
    headCells: {
      style: {
        "font-weight": "bold",
        "font-size": "12px",
        color: "#5F6B77",
      },
    },
  };

  const columns = [
    {
      name: "Name",
      selector: (row: any) =>
        `${row?.profileInfo?.firstName || ""} ${row?.profileInfo?.lastName || ""}` ||
        "-",
    },
    {
      name: "Email",
      selector: (row: any) => row?.Email || "-",
      cell: (row: any) => (
        <div className="table-cell-content">{row?.Email || "-"}</div>
      ),
    },
    {
      name: "Created At",
      cell: (row: any) => {
        const inputTimestamp = row?.createdAt || "2023-09-14T01:31:02.515Z";
        const timestamp = new Date(inputTimestamp);

        const options = { year: "numeric", month: "short", day: "2-digit" };
        // @ts-ignore
        const formattedDate = timestamp.toLocaleDateString("en-US", options);

        return <div className="table-cell-content">{formattedDate}</div>;
      },
    },
    {
      name: "Switch",
      cell: (row: any) => {
        return (
          <div className="table-cell-content">
            <button
              className="primaryBtn !p-1 !px-3"
              onClick={() => hanldeAccountSwitch(row)}
            >
              Login
            </button>
          </div>
        );
      },
    },
    {
      name: "Delete",
      cell: (row: any) => (
        <div>
          <div
            className="cursor-pointer rounded-md bg-[#FEF7F7] p-2"
            onClick={() => handleDeleteUser(row._id)}
          >
            <Icons.DeleteIcon color="red" className="rounded bg-white" />
          </div>
        </div>
      ),
      maxWidth: "30px",
    },
  ];

  const handlePageChange = (page: number) => {
    setcurrentPage(page);
  };

  const handlePerRowsChange = (perPage: number, page: number) => {
    setPerPage(perPage);
    setcurrentPage(page);
  };

  const handleDeleteUser = async (id: string) => {
    setLoading(true);
    await userService.deleteUser(id);
    await fetchUser();
    setSearchtext("");
    setLoading(false);
  };

  const hanldeAccountSwitch = (row: any) => {
    if (row?._id) {
      const token = currentUser?.token || "";
      const URL = `${window.location.origin}/admin/user_login/?userEmail=${row?.Email}&token=${token}`;
      navigator.clipboard.writeText(URL).then(
        () => {
          toast({
            title: "URL copied to clipboard!",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        },
        () => {
          toast({
            title: "Failed to copy URL to clipboard!",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        },
      );
    }
  };

  const debouncedSetSearchtext = useCallback(
    debounce((value: string) => {
      setSearchtext(value);
    }, 500),
    [],
  );

  const fetchUser = useCallback(async () => {
    setLoading(true);
    const res = await userService.getuserList({
      limit: perPage,
      search: searchtext,
      page: searchtext ? 1 : currentPage,
    });
    if (res?.success) {
      setUser(res.data?.data?.allUser);
      setTotalRows(res.data?.data?.total);
      setLoading(false);
    } else {
      setUser([]);
      setTotalRows(0);
      setLoading(false);
    }
  }, [perPage, searchtext, currentPage]);

  useEffect(() => {
    fetchUser();
  }, [currentPage, perPage, searchtext]);

  return (
    <div>
      <InputGroup className="rounded-md">
        <InputLeftElement pointerEvents="none">
          <Icons.SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input
          type="text"
          rounded="md"
          placeholder="Search User Here..."
          onChange={(e) => debouncedSetSearchtext(e.target.value)}
        />
      </InputGroup>
      <DataTable
        columns={columns}
        progressPending={loading}
        customStyles={customStyles}
        data={user}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        fixedHeader
        fixedHeaderScrollHeight="600px"
      />
    </div>
  );
};

export default UserLogin;
