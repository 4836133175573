import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate } from "@tanstack/react-router";

import userService from "@/services/user.service";

const useResourceAccessCheck = (
  resourceType: string,
): UseQueryResult<
  {
    isAccess: true;
  },
  Error
> => {
  return useQuery({
    queryKey: ["resourceAccessCheck", resourceType],
    queryFn: async () => userService.getUserResourceAccess(resourceType),
  });
};

export const useAutoPilotAccessCheck = () => {
  const { data: responseData, isLoading } =
    useResourceAccessCheck("auto-pilot");
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !responseData?.isAccess) {
      navigate({
        to: "/auto-pilot",
      });
    }
  }, [responseData?.isAccess, isLoading, navigate]);
};

export default useResourceAccessCheck;
