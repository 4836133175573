import AutoPilotLandingPage from "@/pages/AutoPilotLandingPage";
import { userStore } from "@/stores/user.store";
// import { userStore } from "@/stores/user.store";
import { FileRoute } from "@tanstack/react-router";

export const Route = new FileRoute("/auto-pilot/").createRoute({
  component: AutoPilotPage,
  errorComponent: () => <div>Error</div>,
});

function AutoPilotPage() {
  const currentUser = userStore((state) => state.currentUser);
  if (
    currentUser?.role?.toLocaleLowerCase() !== "admin" &&
    !currentUser?.Email.includes("simplydriven.com")
  ) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh_-_80px)] ">
        Access Not found
      </div>
    );
  }
  return <AutoPilotLandingPage />;
}

export default AutoPilotPage;
