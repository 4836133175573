import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { TableData } from "@/types/table.types";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import leadService from "@/services/leads.service";
import { queryClient } from "@/lib/queryClient";
import tableService from "@/services/table.service";
import Icons from "@/components/Icons";

type Props = {
  isConfigured: boolean;
  isOpen: boolean;
  tableData?: TableData;
  onClose: (isRefetch: boolean) => void;
};

const frequencyList = [
  {
    label: "Daily",
    value: "1",
  },
  {
    label: "Weekly",
    value: "7",
  },
  {
    label: "Biweekly",
    value: "15",
  },
  {
    label: "Monthly",
    value: "30",
  },
];

const Configurations = ({
  isConfigured,
  isOpen,
  tableData,
  onClose,
}: Props) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [employmentTypes, setEmploymentTypes] = useState("");
  const [jobRequirements, setJobRequirements] = useState("");
  const [jobTitles, setJobTitles] = useState("");
  const [companyTypes, setCompanyTypes] = useState("");
  const [employer, setEmployer] = useState("");
  const [datePosted, setDatePosted] = useState("");
  const [limit, setLimit] = useState(10);
  const [frequencyToUpdate, setFrequencyToUpdate] = useState<Option | null>(
    null,
  );

  const otherPayloads = [
    {
      name: "Employment Types",
      state: employmentTypes,
      description:
        "Find jobs of particular employment types, specified as a comma delimited list of the following values: FULLTIME, CONTRACTOR, PARTTIME, INTERN.",
      setState: (value: any) => {
        setEmploymentTypes(value);
      },
    },
    {
      name: "Job Requirements",
      state: jobRequirements,
      description:
        "Find jobs with specific requirements, specified as a comma delimited list of the following values: under_3_years_experience, more_than_3_years_experience, no_experience, no_degree.",
      setState: (value: any) => {
        setJobRequirements(value);
      },
    },
    {
      name: "Job Titles",
      state: jobTitles,
      description:
        "Find jobs with specific job titles - specified as a comma (,) separated list of job_titles filter values (i.e. filter value field) as returned by the Search Filters endpoint.",
      setState: (value: any) => {
        setJobTitles(value);
      },
    },
    {
      name: "Company Types",
      state: companyTypes,
      description:
        "Find jobs posted by companies of certain types - specified as a comma (,) separated list of company_types filter values (i.e. filter value field) as returned by the Search Filters endpoint.",
      setState: (value: any) => {
        setCompanyTypes(value);
      },
    },
    {
      name: "Employer",
      state: employer,
      description:
        "Find jobs posted by specific employers - specified as a comma (,) separated list of employer filter values (i.e. filter value field) as returned by the Search Filters endpoint.",
      setState: (value: any) => {
        setEmployer(value);
      },
    },
    {
      name: "Date Posted",
      state: datePosted,
      description:
        "Find jobs posted within the time you specify. It could be one of these; all, today, 3days, week, month",
      setState: (value: any) => {
        setDatePosted(value);
      },
    },
    {
      name: "Number of jobs to fetch",
      state: limit,
      description: "Max limit is 100",
      setState: (value: any) => {
        setLimit(value);
      },
    },
  ];

  const handleAutoPilot = async () => {
    setIsLoading(true);
    const response = await leadService.runPersanaJobOpenings(
      {
        query,
        datePosted,
        employmentTypes,
        jobRequirements,
        jobTitles,
        companyTypes,
        employer,
        limit,
        frequencyToUpdate: frequencyToUpdate?.value,
      },
      "create-job-openings-table",
    );
    queryClient.refetchQueries({
      queryKey: ["tables"],
    });
    window.open(`/table/${response?.data?.tableData?._id}`, "_blank");
    setIsLoading(false);
    onClose(true);
  };

  const handleTriggerFlow = async () => {
    setIsLoading(true);
    await leadService.runPersanaJobOpenings(
      {
        tableId: tableData?._id || "",
      },
      "update-job-openings-table",
    );
    window.open(`/table/${tableData?._id}`, "_blank");
    onClose(true);
    setIsLoading(false);
  };

  const updatePreferences = async () => {
    setIsLoading(true);
    const response = await tableService.updateTableDataV2(
      tableData?._id || "",
      {
        metaData: {
          query,
          datePosted,
          employmentTypes,
          jobRequirements,
          jobTitles,
          companyTypes,
          employer,
          limit,
          frequencyToUpdate: frequencyToUpdate?.value,
        },
      },
    );
    if (response.success) {
      toast({
        title: "Success",
        description: "Successfully updated the preferences.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      if (query !== tableData?.metaData?.query) {
        handleTriggerFlow();
      }
    } else {
      toast({
        title: "Error",
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    onClose(true);
    setIsLoading(false);
  };

  useEffect(() => {
    const freq = frequencyList?.find(
      (item) => item.value === tableData?.metaData?.frequencyToUpdate,
    ) as Option;

    setQuery(tableData?.metaData?.query || "");
    setEmploymentTypes(tableData?.metaData?.employmentTypes || "");
    setDatePosted(tableData?.metaData?.datePosted || "");
    setJobRequirements(tableData?.metaData?.jobRequirements || "");
    setEmployer(tableData?.metaData?.employer || "");
    setCompanyTypes(tableData?.metaData?.companyTypes || "");
    setJobTitles(tableData?.metaData?.jobTItles || "");
    setLimit(tableData?.metaData?.limit || 10);
    setFrequencyToUpdate(freq);
  }, [tableData]);

  return (
    <Modal
      size={"5xl"}
      isOpen={isOpen}
      onClose={() => onClose(false)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <p>Autopilot - Persana Job Openings Preferences</p>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="custom-scrollbar max-h-[500px] min-h-[500px] overflow-y-auto">
          <VStack spacing={4} align={"stretch"} mt={4}>
            {isConfigured && (
              <Button
                colorScheme="purple"
                variant={"outline"}
                leftIcon={<Icons.RefreshIcon />}
                size={"sm"}
                className="w-fit"
                onClick={handleTriggerFlow}
                isLoading={isLoading}
              >
                Trigger Flow
              </Button>
            )}
            <VStack spacing={2} align="stretch">
              <Text fontSize="md" fontWeight="semibold">
                How frequently you want to find new jobs?
              </Text>
              <MultiSelectDropdown
                isMulti={false}
                placeholder="Frequency to find new jobs"
                closeMenuOnSelect
                options={frequencyList}
                value={frequencyToUpdate}
                onChange={(selected) =>
                  setFrequencyToUpdate(selected as Option)
                }
              />
            </VStack>
            <VStack spacing={1} align="stretch">
              <Text fontSize="md" fontWeight="semibold">
                Query <span className="text-red-400">*</span>
              </Text>
              <Input
                placeholder="Enter Query"
                size="md"
                variant="outline"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                borderRadius="md"
                focusBorderColor="blue.400"
              />
              <p className="pl-0.5 pt-0.5 font-title text-xs font-medium tracking-wide text-darkTextGray">
                Free-form jobs search query. It is highly recommended to include
                job title and location as part of the query. It could be web
                development in chicago, developer in germany
              </p>
            </VStack>
            {otherPayloads?.map((item) => (
              <VStack spacing={1} align="stretch">
                <Text fontSize="md" fontWeight="semibold">
                  {item.name}
                </Text>
                <Input
                  placeholder={`Enter ${item.name}`}
                  size="md"
                  variant="outline"
                  value={item.state}
                  onChange={(e) => item.setState(e.target.value)}
                  borderRadius="md"
                  focusBorderColor="blue.400"
                />
                <p className="pl-0.5 pt-0.5 font-title text-xs font-medium tracking-wide text-darkTextGray">
                  {item.description}
                </p>
              </VStack>
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            size={"sm"}
            mr={3}
            onClick={() => onClose(false)}
          >
            Close
          </Button>
          <Button
            colorScheme="blue"
            variant="outline"
            size={"sm"}
            isLoading={isLoading}
            isDisabled={!query || !frequencyToUpdate?.value}
            onClick={() =>
              isConfigured ? updatePreferences() : handleAutoPilot()
            }
          >
            {isConfigured ? "Update" : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Configurations;
