import React from "react";

import SourceImg from "@/assets/svgs/auto-pilot-source.svg";
import { CustomNodeProps } from "@/components/Common/AutoPilot/types";
import CustomNodeCard from "../Common/AutoPilot/CustomNodeCard";
import { Handle, Position } from "@xyflow/react";

const SourceNode: React.FC<CustomNodeProps> = ({ data }) => {
  return (
    <>
      <CustomNodeCard
        isActive={data.showToolbar || false}
        title="Job Openings Source"
        subtitle="Source"
        configStatus="Configured"
        isConfigured={!!data.isConfigured}
        isDisabled={false}
        imgSrc={SourceImg}
      />
      <Handle type="source" position={Position.Bottom} id="a" />
      <Handle type="source" position={Position.Bottom} id="b" />
    </>
  );
};

export default SourceNode;
