import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import MainContent from "./MainContent";
import { useModalStore } from "@/stores/modal.store";
import { useEnrichStore } from "@/stores/enrich.store";
import Sidebar from "./Sidebar";
import { useState } from "react";
import { IntegrationCategoryType } from "../Enrichment/constants";

const MarketplaceModal = () => {
  const [activeCategory, setActiveCategory] =
    useState<IntegrationCategoryType>("All");
  const isOpenModal = useModalStore((state) => state.isOpenModal);
  const modalData = useModalStore((state) => state.modalData);
  const updateState = useModalStore((state) => state.updateState);
  const { updateState: updateEnrichModalState } = useEnrichStore();

  const handleModalClose = () => {
    updateState({
      isOpenModal: false,
      modalData: { modalType: "", metaData: "" },
    });
    if (modalData?.metaData?.openPreviousModal) {
      setTimeout(() => {
        updateEnrichModalState({
          isOpenEnrichModal: true,
        });
      }, 100);
    }
  };

  const handleActiveCategory = (categoryType: IntegrationCategoryType) => {
    setActiveCategory(categoryType);
  };

  return (
    <Modal
      isCentered
      onClose={handleModalClose}
      isOpen={isOpenModal && modalData.modalType === "marketplace"}
      motionPreset="scale"
      size="6xl"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent className="rounded-[20px] h-[680px]">
        <div
          className="h-[18px]"
          style={{
            background: "linear-gradient(127deg, #693DC7 0%, #BF72E1 100%) ",
            boxShadow: "0px 6px 58px 0px rgba(121, 145, 173, 0.20)",
            borderRadius: "6px 6px 0 0",
          }}
        />
        <ModalHeader>Data & Agents Marketplace</ModalHeader>
        <ModalBody className="font-primary relative inline-block transform overflow-hidden text-left align-bottom transition-all sm:my-8 sm:w-full sm:max-w-[70rem] sm:align-middle h-full w-full bg-white !p-0 !m-0">
          <div className="flex justify-between">
            <Sidebar
              activeCategory={activeCategory}
              handleCategory={handleActiveCategory}
            />
            <MainContent activeCategory={activeCategory} />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MarketplaceModal;
