import React, { useEffect } from "react";
import ConfigStatus from "./ConfigStatus";
import { twMerge } from "tailwind-merge";

interface CardProps {
  rightIcon?: string;
  title: string;
  subtitle: string;
  isConfigured: boolean;
  isDisabled: boolean;
  configStatus: string;
  imgSrc: string;
  isActive: boolean;
  className?: string;
  engagementType?: string;
  enableActiveStyleOnHover?: boolean;
  onCardClick?: (engagementType: string) => void;
}

const CustomNodeCard: React.FC<CardProps> = ({
  rightIcon,
  title,
  subtitle,
  isDisabled,
  isConfigured,
  configStatus,
  imgSrc,
  isActive,
  className,
  enableActiveStyleOnHover = false,
  onCardClick,
  engagementType,
}) => {
  const [isCardActive, setIsCardActive] = React.useState(false);

  useEffect(() => {
    setIsCardActive(isActive);
  }, [isActive]);

  return (
    <div
      className={`${twMerge(
        "w-[330px] rounded-[8px] border text-center",
        className,
      )} ${isDisabled && "pointer-events-none opacity-50"} ${isCardActive ? "border-[#5024DC]" : isConfigured ? "border-[#078A52]" : "border-[#D0D4DC]"} ${
        enableActiveStyleOnHover && "cursor-pointer"
      }`}
      onMouseEnter={() => {
        if (enableActiveStyleOnHover) setIsCardActive(true);
      }}
      onMouseLeave={() => {
        if (enableActiveStyleOnHover) setIsCardActive(false);
      }}
      onClick={() => {
        onCardClick && onCardClick(engagementType || "");
      }}
    >
      <div className="flex items-center justify-between px-[16px] py-[12px]">
        <div className="flex items-center gap-5 text-center">
          <img src={imgSrc} alt="" className="size-[40px]" />
          <div>
            <p className="grow truncate text-[16px] font-bold">{title}</p>
            <p className="text-[14px] font-medium text-[#7E889F]">{subtitle}</p>
          </div>
        </div>
        {rightIcon && <img src={rightIcon} alt="" className="size-[24px]" />}
      </div>
      <ConfigStatus
        isConfigured={isConfigured}
        title={isConfigured ? configStatus : "Not configured"}
      />
    </div>
  );
};

export default CustomNodeCard;
