import { FileRoute } from "@tanstack/react-router";

import AutoPilotMultiSourceJobOpeningsWorkflow from "@/components/AutoPilotMultiSourceJobsWorkflow";
import { useAutoPilotAccessCheck } from "@/pages/hooks/autoPilot";

export const Route = new FileRoute(
  "/auto-pilot/multi-source-job-openings-workflow",
).createRoute({
  component: MultiSourceJobsWorkflowPage,
  errorComponent: () => <div>Error</div>,
});

function MultiSourceJobsWorkflowPage() {
  useAutoPilotAccessCheck();

  return <AutoPilotMultiSourceJobOpeningsWorkflow />;
}

export default MultiSourceJobsWorkflowPage;
