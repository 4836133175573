import NiaProfile from "@/assets/images/lucy.png";
const Header = () => {
  return (
    <div className="flex items-center justify-center gap-2 px-[32px] py-[16px] border-b border-[#F0EFF1]">
      <img src={NiaProfile} />
      <p className="text-[20px] font-[600] text-[#14171F]">
        Hi, I’m Nia, your AI SDR. Let’s maximize your outbound efforts!
      </p>
    </div>
  );
};

export default Header;
