import { FileRoute } from "@tanstack/react-router";

import AutoPilotLeadsWorkflow from "@/components/AutoPilotLeadsWorkflow";
import { useAutoPilotAccessCheck } from "@/pages/hooks/autoPilot";

export const Route = new FileRoute("/auto-pilot/leads-workflow").createRoute({
  component: LeadsWorkflowPage,
  errorComponent: () => <div>Error</div>,
});

function LeadsWorkflowPage() {
  useAutoPilotAccessCheck();

  return <AutoPilotLeadsWorkflow />;
}

export default LeadsWorkflowPage;
