import { FileRoute } from "@tanstack/react-router";

import AutoPilotJobOpeningsWorkflow from "@/components/AutoPilotJobsWorkflow";
import { useAutoPilotAccessCheck } from "@/pages/hooks/autoPilot";

export const Route = new FileRoute(
  "/auto-pilot/job-openings-workflow",
).createRoute({
  component: JobsWorkflowPage,
  errorComponent: () => <div>Error</div>,
});

function JobsWorkflowPage() {
  useAutoPilotAccessCheck();

  return <AutoPilotJobOpeningsWorkflow />;
}

export default JobsWorkflowPage;
