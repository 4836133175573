import Select from "react-select";

import { formatNumber } from "@/lib/utils";
import { PaginationType } from "@/stores/searchleads.store";
import { useSearchLeadsStore } from "./store";
import { usePeopleSearch } from "./hooks/usePeopleSearch";
import { Box, BoxProps } from "@chakra-ui/react";
import { userStore } from "@/stores/user.store";
import CustomSelection from "./components/CustomSelection";

interface Props extends BoxProps {
  paginationData: PaginationType;
}

const Pagination = ({ paginationData, ...props }: Props) => {
  const { mutateAsync: searchPeople, isPending } = usePeopleSearch();
  const currentUser = userStore((state) => state.currentUser);
  const planType = currentUser.planType;
  const selectedLeads = useSearchLeadsStore((state) => state.selectedLeads);
  const selectionAction = useSearchLeadsStore((state) => state.selectionAction);
  const selectionCount = useSearchLeadsStore((state) => state.selectionCount);

  const currentSearchPayload = useSearchLeadsStore(
    (state) => state.currentSearchPayload,
  );
  const isUseMixrank = userStore((state) => state.isUseMixrank);

  const currentPageData = formatNumber(
    Math.min(
      paginationData.page * paginationData.per_page,
      paginationData.total_entries,
    ),
  );
  const totalEntries = formatNumber(+paginationData.total_entries);

  const options = Array.from(
    { length: Math.min(paginationData.total_pages, 20) },
    (_, i) => ({
      value: i + 1,
      label: String(i + 1),
    }),
  );

  const handleSearch = (pageNumber: number) => {
    searchPeople({
      payload: {
        ...currentSearchPayload,
        page: pageNumber,
      },
      isUseMixrank,
    });
  };
  const isSelected = () => {
    if (
      selectionAction === "select-all" &&
      planType?.toLocaleLowerCase() !== "free"
    ) {
      return true;
    }
    if (selectionCount && planType?.toLocaleLowerCase() !== "free") {
      return true;
    }
    if (selectedLeads.length) {
      return true;
    }
    return false;
  };
  return (
    <Box className="my-3" {...props}>
      <div className="flex items-center gap-2">
        {isSelected() && <CustomSelection showLabel="full" />}
        <span className="text-[12px] text-[#1a1a1a]">
          {currentPageData} of {totalEntries}
        </span>
        <div
          className={`min-w-[80px] ${paginationData?.total_pages < 2 ? "hidden" : ""}`}
        >
          <Select
            defaultValue={options[0]}
            options={options}
            value={options.find(
              (option) => option.value === paginationData.page,
            )}
            menuPosition="fixed"
            isSearchable
            isClearable={false}
            isLoading={isPending}
            styles={{
              singleValue: (base) => ({
                ...base,
                textAlign: "center",
              }),
              input: (base) => ({
                ...base,
                caretColor: "transparent", // Hide the cursor
              }),
              option: (base) => ({
                ...base,
                whiteSpace: "nowrap", // Prevent text wrapping
              }),
            }}
            onChange={(option) => {
              handleSearch(option?.value || 1);
            }}
          />
        </div>
      </div>
    </Box>
  );
};

export default Pagination;
