import { useSearchLeadsStore } from "@/components/PeopleSearch/store.ts";

import { Badge } from "@chakra-ui/react";

const scrollToElement = (elId: string) => {
  const el = document.getElementById(elId);
  el?.scrollIntoView({
    behavior: "smooth",
    inline: "center",
    block: "center",
  });
  el!.classList.add("ping-border");
  (el?.querySelector("input, select, textarea") as HTMLInputElement)?.focus?.({
    preventScroll: true,
  });
  setTimeout(() => {
    el?.classList.remove("ping-border");
  }, 1000);
};
export const NaturalFilterValue = ({ field }: { field?: string }) => {
  const id = useSearchLeadsStore((state) => state.instanceId);

  const fieldValues = useSearchLeadsStore((state) =>
    field ? state.currentSearchPayload?.[field] ?? null : null,
  );
  const valueLabels = useSearchLeadsStore((state) => state.valueLabels);
  if (!field) return null;
  const count = fieldValues instanceof Array ? fieldValues.length : 0;
  const value =
    fieldValues instanceof Array
      ? fieldValues
          .filter(Boolean)
          .map((item) => (typeof item === "object" ? item.label : item))
          .toSorted((a, b) => b.length - a.length)
          .pop()
      : String(fieldValues);

  const label = valueLabels?.[value] ?? value;
  return (
    <Badge
      onClick={() => scrollToElement(`${id}-${field}`)}
      colorScheme={"purple"}
      transition={"all 0.2s ease-in-out"}
      className="  w-[fit-content] !cursor-pointer !rounded hover:!scale-[1.01]"
    >
      <span className="flex items-center gap-1">
        {label}
        {count > 1 ? (
          <span className=" right-[1px] top-0 z-0 size-[min-content] w-[min-content]  scale-75 rounded bg-[white] px-[4px] text-[18px]">
            {count}
          </span>
        ) : null}
      </span>
    </Badge>
  );
};
