// import { useCallback, useEffect, useState } from "react";
// import { Switch, Tooltip } from "@chakra-ui/react";
// import userService from "@/services/user.service";
// import { userStore } from "@/stores/user.store";
// import { useSearchLeadsStore } from "@/components/PeopleSearch/store.ts";

import { NaturalFilters } from "./NaturalFilters";

interface Props {
  isDisabled: boolean;
}

const FiltersSection = ({ isDisabled }: Props) => {
  return (
    <div className="zindex-[dropdown] flex h-[74px] grow items-center justify-between rounded-xl rounded-bl-none border border-[#F0EFF1] px-[32px] py-[6px]">
      <div className="flex grow items-center gap-[16px]">
        {/* <Tooltip
          hasArrow
          label="Automatically search for people using the latest applied filters on a weekly basis."
        >
          <div className="flex !h-[35px] cursor-pointer items-center rounded-md border !border-[#8080801c] !bg-gray px-2 !text-sm hover:!shadow-md">
            <label className="mr-2 cursor-pointer" htmlFor="auto-search-switch">
              Auto Search
            </label>
            <Switch
              sx={{
                ".chakra-switch__track[data-checked]:not([data-theme])": {
                  backgroundColor: "#5024DC",
                },
              }}
              isChecked={isEnabledAutoSearch}
              size="md"
              onChange={(e) => {
                handleAutoSearch(e.target.checked);
              }}
              id="auto-search-switch"
            />
          </div>
        </Tooltip> */}
        <NaturalFilters isDisabled={isDisabled} />
      </div>
    </div>
  );
};

export default FiltersSection;
