import { useQueryClient } from "@tanstack/react-query";
import { FileRoute } from "@tanstack/react-router";
import { useEffect, useRef, useState } from "react";
import { z } from "zod";

import LottiePlayer from "@/components/Common/LottiePlayer";
import TablePage from "@/pages/table";

import { useTableData } from "@/hooks/useTableData";
import { State, useTableStore } from "@/stores/table.store";

import MainLoader from "@/assets/lottieFiles/main_loader.lottie";
import { useRunningProcesses } from "@/hooks/useRunningProcesses";
import { useEnrichStore } from "@/stores/enrich.store";
import { useProcessingStore } from "@/stores/processing.store";
import { userStore } from "@/stores/user.store";
import { TableData } from "@/types/table.types";

export const Route = new FileRoute("/table/$tableId").createRoute({
  parseParams: (params) => ({
    tableId: z.string().parse(String(params.tableId)),
  }),
  component: TableComponent,
  validateSearch: z.object({
    page: z
      .union([
        z.literal("first"), // Literal string 'first'
        z.literal("last"), // Literal string 'last'
        z.number().positive(), // Only positive numbers
      ])
      .optional(),
  }),
});

function TableComponent() {
  const params = Route.useParams();
  const queryClient = useQueryClient();
  const isFirstRender = useRef(true);
  const [reloadKey, setReloadKey] = useState(1);

  const isTablePageReRender = useTableStore(
    (state) => state.isTablePageReRender,
  );
  const updateState = useTableStore((state) => state.updateState);
  const tableData = useTableStore((state) => state.tableData);
  const isFiltering = useTableStore((state) => state.isFiltering);
  const queryParams = useTableStore((state) => state.queryParams);
  const updateEnrichmentState = useEnrichStore((state) => state.updateState);
  const updateProcessingState = useProcessingStore(
    (state) => state.updateState,
  );
  const refreshCurrentUser = userStore((state) => state.refreshCurrentUser);

  const {
    data,
    isLoading,
    isSuccess,
    isFetched,
    error,
    isRefetching,
    refetch,
  } = useTableData(params.tableId);
  const isLoadingView = useTableStore(
    (state) => state.tableData?.metaData?.isLoadingView,
  );

  useEffect(() => {
    if (isFetched && data) {
      let newState: Partial<State> = {
        ...data,
        sortingProcessingColumnKey: "",
      };
      if (!isFiltering) {
        newState = {
          ...newState,
          tableFilters: {
            filters: data?.tableData.metaData?.filters || [],
            open: false,
          },
        };
      }
      if (isFirstRender.current && !data?.tableData.metaData?.filters?.length) {
        newState.initialTableAndRowsData = data;
      }

      updateState(newState);
      isFirstRender.current = false;
      if (isTablePageReRender) {
        setReloadKey(Math.random());
        updateState({
          isTablePageReRender: false,
        });
      }
    }
  }, [isFetched, isFiltering, data, updateState]);
  const { data: runningProcessesData } = useRunningProcesses(params.tableId);

  useEffect(() => {
    if (runningProcessesData?.data) {
      updateProcessingState({
        processingData: runningProcessesData.data,
      });
    }
  }, [runningProcessesData]);

  useEffect(() => {
    if (queryParams.pageNumber) {
      refetch();
    }
  }, [queryParams]);

  useEffect(() => {
    refreshCurrentUser();
    return () => {
      updateEnrichmentState({
        selectedEnrichments: null,
        isOpenEnrichModal: false,
      });
      // Clear the table cache data
      queryClient.refetchQueries({
        queryKey: ["table", params.tableId],
      });
      // Clear table state
      updateState({
        totalPages: 0,
        totalRows: 0,
        currentPage: 1,
        rowsData: [],
        isSelectedAll: false,
        selectedRowsId: [],
        tableData: {} as TableData,
        tableFilters: {
          filters: [],
          open: false,
        },
        queryParams: {
          limit: 500,
          pageNumber: 1,
          sortingKey: "",
          sortOrder: "ASC",
        },
      });
    };
  }, [params]);

  if (!isLoading && (!isSuccess || !data || !tableData?._id || error)) {
    return (
      <div className="flex flex-col items-center">
        <img
          src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          alt=""
          className="size-[200px]"
        />
        <span className="inline-block text-lg text-red-600">
          {error?.message}
        </span>
        <span>
          No Data found for table <b>{params.tableId}</b>
        </span>
      </div>
    );
  }

  return (
    <>
      {(isLoading || isLoadingView) && (
        <div className="fixed z-[1000001] flex h-[100vh] w-[95vw] flex-col items-center justify-center backdrop-blur-3xl">
          <LottiePlayer src={MainLoader} />
          <p className="gradient-text text-2xl font-medium text-primaryPurple">
            Firing up Persana's Brain
          </p>
        </div>
      )}
      <TablePage isRefetching={isRefetching} key={reloadKey} />
    </>
  );
}

export default TableComponent;
