/* eslint-disable @typescript-eslint/no-explicit-any */
import { EnrichmentMetaData } from "@/types/table.types";
import { useEffect, useMemo, useReducer } from "react";

import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import { useEnrichStore } from "@/stores/enrich.store";
import { convertSlateToText } from "@/utils";
import ConditionalFormula from "../../Common/ConditionalFormula";
import CustomTextEditor from "../../Common/CustomTextEditor";
import WatchTutorial from "@/components/Common/WatchTutorial";
import {
  Input,
  NumberInput,
  NumberInputField,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import CustomAccordion from "@/components/Common/CustomAccordion";
import { Select } from "chakra-react-select";
import { CheckboxFull } from "@/components/Common/CheckboxFull";
import { useTableStore } from "@/stores/table.store";
import { Descendant } from "slate";
import Icons from "@/components/Icons";

const initialSlateValue: (Descendant & { type: "paragraph" })[] = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];
enum ExperienceLevel {
  Junior = "entry",
  MidLevel = "mid",
  Senior = "senior",
  Executive = "executive",
}
const experienceLevel = [
  { label: "Junior", value: ExperienceLevel.Junior },
  { label: "Mid-Level", value: ExperienceLevel.MidLevel },
  { label: "Senior", value: ExperienceLevel.Senior },
  { label: "Executive", value: ExperienceLevel.Executive },
];
const initialState = {
  company_name: initialSlateValue,
  company_website: initialSlateValue,
  job_title: initialSlateValue,
  location: initialSlateValue,
  experience_level: ExperienceLevel.Senior,
  job_description: initialSlateValue,
  has_remote: false,
  posted_within: 30,
  page: 1,
  per_page: 10,
};

interface EnrichmentDataType extends EnrichmentMetaData {
  payload: typeof initialState;
}

type State = typeof initialState;

type Action<Key extends keyof State> = {
  key: Key;
  value: State[Key];
};

function reducer<Key extends keyof State>(state: State, action: Action<Key>) {
  return { ...state, [action.key]: action.value };
}

const FindLeadMagicJobs = () => {
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const columns = useTableStore((state) => state.tableData.columns);

  const [payloadDetails, dispatch] = useReducer(reducer, initialState);
  const updateDetails = <Key extends keyof State>(
    key: Key,
    value: State[Key],
  ) => dispatch({ key, value });

  const isValid = useMemo(() => {
    return convertSlateToText(payloadDetails.job_title).text !== "";
  }, [payloadDetails]);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType | undefined;
      if (enrichmentData?.payload) {
        Object.entries(enrichmentData.payload).forEach(([key, value]) => {
          dispatch({ key: key as keyof State, value });
        });
      }
    }
  }, [viewMode, selectedColumnToEdit]);
  const getDependencies = () => {
    const dep = Object.values(payloadDetails)
      .filter((value) => value instanceof Array)
      .map((value) => convertSlateToText(value as Descendant[]).text)
      .map((value) => value.trim())
      .map(
        (str) =>
          str.match(/\{\{(.*?)\}\}/g)?.map((match) => match.slice(2, -2)) ?? [],
      )
      .flat()
      .map((colName) => columns?.find((col) => col.name === colName))
      .map((col) => col?._id) as string[];
    return dep;
  };
  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText={"Watch the tutorial to learn how to find active jobs."}
          link={""}
          credits={payloadDetails.per_page}
        />

        <CustomAccordion
          description="*Required"
          title="Job Title"
          isDefaultOpen={true}
          isOpen={true}
        >
          <CustomTextEditor
            isInline
            editorHeight={"1rem"}
            slateValue={payloadDetails.job_title}
            setSlateValue={(value: any) => updateDetails("job_title", value)}
            placeholder={"Title of the job."}
          />
        </CustomAccordion>
        <CustomAccordion
          title="Count"
          isDefaultOpen={true}
          isOpen={true}
          description="Number of jobs to be fetched. (1 Job = 1 credent)"
        >
          <NumberInput
            value={payloadDetails.per_page}
            min={1}
            onChange={(value) => {
              if (value) {
                updateDetails("per_page", parseInt(value));
              }
            }}
          >
            <NumberInputField />
          </NumberInput>
        </CustomAccordion>
        <CustomAccordion
          title="Company Name"
          isDefaultOpen={true}
          isOpen={true}
        >
          <CustomTextEditor
            isInline
            editorHeight={"1rem"}
            slateValue={payloadDetails.company_name}
            setSlateValue={(value: any) => updateDetails("company_name", value)}
            placeholder={"Name of the company."}
          />
        </CustomAccordion>
        <CustomAccordion
          title="Company Website"
          isDefaultOpen={true}
          isOpen={true}
        >
          <CustomTextEditor
            isInline
            editorHeight={"1rem"}
            slateValue={payloadDetails.company_website}
            setSlateValue={(value: any) =>
              updateDetails("company_website", value)
            }
            placeholder={"Website of the company."}
          />
        </CustomAccordion>
        <CustomAccordion title="Location" isDefaultOpen={true} isOpen={true}>
          <CustomTextEditor
            editorHeight={"2rem"}
            slateValue={payloadDetails.location}
            setSlateValue={(value: any) => updateDetails("location", value)}
            placeholder={"Enter the location."}
          />
        </CustomAccordion>
        <CustomAccordion
          title="Experience Level"
          isDefaultOpen={true}
          isOpen={true}
        >
          {/* <CustomTextEditor
            editorHeight={"4rem"}
            slateValue={payloadDetails.experience_level}
            setSlateValue={(value: any) =>
              updateDetails("experience_level", value)
            }
            placeholder={`Start typing or use the dropdown to select a column.`}
          /> */}
          <Select
            colorScheme="purple"
            size={"sm"}
            // value={experienceLevel[0]}
            value={experienceLevel.find(
              (item) => item.value === payloadDetails.experience_level,
            )}
            onChange={(value: any) =>
              updateDetails("experience_level", value.value)
            }
            options={experienceLevel}
          />
        </CustomAccordion>

        <CustomAccordion
          title="Job Description"
          isDefaultOpen={true}
          isOpen={true}
          description=' e.g. python "Software Engineer"'
        >
          <CustomTextEditor
            editorHeight={"4rem"}
            slateValue={payloadDetails.job_description}
            setSlateValue={(value: any) =>
              updateDetails("job_description", value)
            }
            placeholder={"Enter the job description."}
          />
          <Tooltip
            label={
              <div>
                <ol className="p-4">
                  <li>
                    <strong>OR:</strong> Include results matching any of the
                    specified keywords. <br />
                    <em>Example:</em>{" "}
                    <code>
                      &quot;lead generation&quot; OR &quot;sales
                      automation&quot;
                    </code>
                  </li>
                  <li>
                    <strong>AND:</strong> Implicit when terms are separated by
                    spaces (no explicit AND needed). <br />
                    <em>Example:</em>{" "}
                    <code>
                      &quot;lead generation&quot; &quot;CRM integration&quot;
                    </code>
                  </li>
                  <li>
                    <strong>NOT:</strong> Use <code>-</code> to exclude specific
                    keywords. <br />
                    <em>Example:</em>{" "}
                    <code>
                      &quot;sales automation&quot; -&quot;cold outreach&quot;
                    </code>
                  </li>
                  <li>
                    <strong>Exact Phrase Match:</strong> Use quotes (
                    <code>&quot;&quot;</code>) to match exact phrases. <br />
                    <em>Example:</em>{" "}
                    <code>&quot;lead generation software&quot;</code>
                  </li>
                  <li>
                    <strong>Grouping:</strong> Use parentheses for logical
                    grouping. <br />
                    <em>Example:</em>{" "}
                    <code>
                      (&quot;lead generation&quot; OR &quot;sales
                      automation&quot;) -&quot;cold outreach&quot;
                    </code>
                  </li>
                </ol>
              </div>
            }
          >
            <Text className="mt-2 flex items-center gap-2 text-xs text-[gray]">
              <Icons.InformationIcon />
              Use quotes (&quot; &quot;) for exact matches, OR for alternatives,
              and - to exclude terms. Example: &quot;python&quot; OR
              &quot;javascript&quot; -frontend.
            </Text>
          </Tooltip>
        </CustomAccordion>

        <CustomAccordion
          description="Whether the job is remote or not."
          title="Has Remote"
          isDefaultOpen={true}
          isOpen={true}
        >
          <CheckboxFull
            value={payloadDetails.has_remote}
            setValue={(value) => updateDetails("has_remote", value)}
            label="Has Remote?"
            tooltipText="Whether the job is remote or not."
          />
        </CustomAccordion>

        <CustomAccordion
          title="Posted Within"
          isDefaultOpen={true}
          isOpen={true}
          description="Number of days since the job was posted."
        >
          <Input
            type="number"
            value={payloadDetails.posted_within}
            onChange={(e) =>
              updateDetails("posted_within", parseInt(e.target.value))
            }
            min={1}
            placeholder="Enter the number of days."
          />
        </CustomAccordion>

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!isValid}
        apiPayload={{
          dependentColumnIds: getDependencies(),
          payload: payloadDetails,
        }}
      />
    </>
  );
};

export default FindLeadMagicJobs;
