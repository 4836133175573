import { FileRoute } from "@tanstack/react-router";

// import { useAutoPilotAccessCheck } from "@/pages/hooks/autoPilot";
import AutoPilotLeadmagicWorkflow from "@/components/AutoPilotLeadmagicWorkflow";

export const Route = new FileRoute(
  "/auto-pilot/leadmagic-job-openings-workflow",
).createRoute({
  component: LeadmagicJobsWorkflowPage,
  errorComponent: () => <div>Error</div>,
});

function LeadmagicJobsWorkflowPage() {
  // useAutoPilotAccessCheck();

  return <AutoPilotLeadmagicWorkflow />;
}

export default LeadmagicJobsWorkflowPage;
