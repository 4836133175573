import { ReactNode } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
} from "@chakra-ui/react";

type SearchInputAccordionProps = {
  button: ReactNode;
  children: ReactNode;
  btnStyle?: React.CSSProperties;
  defaultIndex?: number;
  props?: AccordionProps;
  showBottomBorder?: boolean;
  numOfAppliedFilters?: number;
};
const SearchInputAccordion = ({
  button,
  children,
  btnStyle = {},
  defaultIndex = 1,
  props,
  showBottomBorder,
  numOfAppliedFilters = 0,
}: SearchInputAccordionProps) => {
  return (
    <Accordion
      defaultIndex={[defaultIndex]}
      allowMultiple
      sx={{
        transition: "all 0.3s ease",
        overflow: "visible",
        borderTop: "1px solid #D0D4DC",
        ...(showBottomBorder && { borderBottom: "1px solid #D0D4DC" }),
      }}
      {...props}
    >
      <AccordionItem
        rounded={"md"}
        sx={{
          transition: "all 0.3s ease",
          border: "none",
        }}
      >
        {({ isExpanded }) => (
          <>
            <h3>
              <AccordionButton
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  ...btnStyle,
                  ...(isExpanded && {
                    backgroundColor: "#F5F3FF",
                  }),
                }}
              >
                {button}
                {numOfAppliedFilters > 0 ? (
                  <span className="text-xs text-[rgb(7,_113,_197)] bg-[rgb(222,_233,_255)] py-[3px] px-[4px] rounded leading-none mr-1">
                    {numOfAppliedFilters} Applied
                  </span>
                ) : null}
                <AccordionIcon color={"#693DC7"} />
              </AccordionButton>
            </h3>
            <AccordionPanel
              pb={4}
              fontFamily={"Inter"}
              overflow={"visible"}
              backgroundColor={"#F5F3FF"}
            >
              {children}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default SearchInputAccordion;
