import FilterModals from "@/components/Enrichment/FilterModals";
import GenerateFormulaModal from "@/components/Enrichment/GenerateFormula";
import EnrichModal from "@/components/Enrichment/Modal";
import DraftEmail from "@/components/Leads/DraftEmail";
import GenerateLeadsModal from "@/components/Leads/GenerateLeadsModal";
import GetStartedModal from "@/components/Leads/GetStartedModal";
import GithubStargazersModal from "@/components/Leads/GithubStargazersModal";
import GoogleSRPModal from "@/components/Leads/GoogleSRPModal";
import HubspotImportModal from "@/components/Leads/HubspotImportModal";
import ImportCSVModal from "@/components/Leads/ImportCSVModal";
import LeadsModal from "@/components/Leads/Modal";
import PricingModal from "@/components/Leads/PricingModal";
import RemoveDuplicateRows from "@/components/Leads/RemoveDuplicateRows";
import ShareTableLinkModal from "@/components/Leads/ShareTableLinkModal";
import TutorialModal from "@/components/Leads/TutorialModal";
import UseTemplateModal from "@/components/Leads/UseTemplateModal";
import WelcomeModal from "@/components/Leads/WelcomeModal";
import PresetsModal from "@/components/Presets";
import CustomErrorBoundary from "./Common/ErrorComponent/CustomErrorBoundary";

import { useUserConfig } from "@/hooks/useUserConfig";
import useSocket from "@/lib/socket";
import { useEnrichStore } from "@/stores/enrich.store";
import { useModalStore } from "@/stores/modal.store";
import { usePresetsStore } from "@/stores/presets.store";
import { useProviderStore } from "@/stores/provider.store";
import React, { useEffect } from "react";
import NewApiKeyModal from "./Enrichment/Common/NewApiKeyModal";
import { PreviewRowScorer } from "./Enrichment/FilterModals/RowScorer/preview";
import { useLeadScoreStore } from "./Enrichment/FilterModals/RowScorer/QueryBuilder/critaria.store";
import HubspotContactImportModal from "./Leads/HubspotContactImportModal";
import LocalBusinessesModal from "./Leads/LocalBusinesses";
import SalesNavImportModal from "./Leads/SalesNavImportModal";
import TeamOverviewModal from "./TeamOverview/TeamOverviewModal";
import AIAgentModal from "./Leads/AIAgentModal";
import SalesGPTModal from "./Leads/SalesGPTModal";
import ImportListModal from "./Leads/ImportListModal";
import WalkThroughModal from "./Common/AutoPilot/WalkThroughModal";
import MarketplaceModal from "./Marketplace/Modal";

const RootComponents = () => {
  const { connect, disconnect } = useSocket();
  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );
  const isOpenEnrichModal = useEnrichStore((state) => state.isOpenEnrichModal);
  const generateFormulaModal = useEnrichStore(
    (state) => state.generateFormulaModal,
  );
  const isOpenPresetsModal = usePresetsStore(
    (state) => state.isOpenPresetsModal,
  );
  const { isOpenModal, modalData, addTeamMembersModal } = useModalStore(
    (state) => state,
  );
  const previewRowScoreModal = useLeadScoreStore(
    (state) => state.previewRowScoreModal,
  );
  useUserConfig();
  const isProviderApiKeyModalOpen = useProviderStore(
    (state) => state.isProviderApiKeyModalOpen,
  );

  useEffect(() => {
    connect();
    return () => {
      console.log("myLog cleanup");
      disconnect();
    };
  }, []);

  return (
    <>
      {selectedEnrichments?.enrichmentName ||
      selectedEnrichments?.showResponse ? (
        <FilterModals />
      ) : null}
      {generateFormulaModal?.isOpen && <GenerateFormulaModal />}
      {isOpenEnrichModal && <EnrichModal />}
      {isOpenPresetsModal && <PresetsModal />}
      {isOpenModal && modalData.modalType === "pricing" && <PricingModal />}
      {isOpenModal && modalData.modalType === "get-started" && (
        <GetStartedModal />
      )}
      {isOpenModal && modalData.modalType === "tutorial" && <TutorialModal />}
      {isOpenModal &&
        (modalData.modalType === "apollo-people-search" ||
          modalData.modalType === "linkedin-company-search") && (
          <GenerateLeadsModal />
        )}
      {isOpenModal && modalData.modalType === "google-srp" && (
        <GoogleSRPModal />
      )}
      {isOpenModal && modalData.modalType === "import-csv" && (
        <ImportCSVModal />
      )}
      {isOpenModal && modalData.modalType === "github-stargazers" && (
        <GithubStargazersModal />
      )}
      {isOpenModal && modalData.modalType === "local-businesses" && (
        <LocalBusinessesModal />
      )}
      {isOpenModal && modalData.modalType === "tutorial" && <TutorialModal />}
      {isOpenModal && modalData.modalType === "use-template" && (
        <UseTemplateModal />
      )}
      {isOpenModal && modalData.metaData?.isLeads && <LeadsModal />}
      {isOpenModal &&
        (modalData.modalType === "job-tracking" ||
          modalData.modalType === "job-opening") && <HubspotImportModal />}
      {isOpenModal && modalData.modalType === "hubspot-contact-import" && (
        <HubspotContactImportModal />
      )}
      {isOpenModal && modalData.modalType === "share-table-link" && (
        <ShareTableLinkModal />
      )}
      {isOpenModal && modalData.modalType === "welcome-modal" && (
        <WelcomeModal />
      )}
      {isOpenModal && modalData.modalType === "ai-agent-modal" && (
        <AIAgentModal />
      )}
      {isOpenModal && modalData.modalType === "sales-gpt-modal" && (
        <SalesGPTModal />
      )}
      {isOpenModal && modalData.modalType === "draft-email" && <DraftEmail />}
      {addTeamMembersModal.isOpen && <TeamOverviewModal />}
      {isOpenModal && modalData.modalType === "remove-duplicate-rows" && (
        <RemoveDuplicateRows />
      )}
      {previewRowScoreModal.isOpen && <PreviewRowScorer />}
      {isProviderApiKeyModalOpen && (
        <React.Suspense fallback={<div>Loading...</div>}>
          <NewApiKeyModal />
        </React.Suspense>
      )}
      {isOpenModal && modalData.modalType === "sales-nav-import" && (
        <SalesNavImportModal />
      )}
      {isOpenModal && modalData.modalType === "import-list-modal" && (
        <ImportListModal />
      )}
      <WalkThroughModal />
      {isOpenModal && modalData.modalType === "marketplace" && (
        <MarketplaceModal />
      )}
    </>
  );
};

const WrappedRootComponents = () => (
  <CustomErrorBoundary>
    <RootComponents />
  </CustomErrorBoundary>
);

export default WrappedRootComponents;
