import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import PremiumIcon from "@/components/Common/PremiumIcon";

import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { TableData } from "@/types/table.types";
import Icons from "@/components/Icons";
import { useNavigate } from "@tanstack/react-router";

const TableName = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isTablePreviewPage =
    window.location.pathname.startsWith("/table-preview");
  const isUserLoggedIn = queryClient.getQueryData(["user-details"]);

  const tableData = useTableStore((state) => state.tableData);
  const isPremium = useTableStore(
    (state) => state.tableData.metaData?.isPremium,
  );
  const tableId = useTableStore((state) => state.tableData._id);
  const filters = useTableStore((state) => state.tableFilters.filters);
  const updateState = useTableStore((state) => state.updateState);

  const tableName = tableData.name;

  const [isEditingName, setIsEditingName] = useState(false);
  const [newName, setNewName] = useState(() => tableName);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (newTableName: string) =>
      tableService.updateTableData(tableId, {
        name: newTableName,
      }),
    onSuccess: (response) => {
      const tableResponse = response as unknown as TableData;
      if (response.success === false) return;

      updateState({
        tableData: {
          ...tableData,
          name: tableResponse.name,
        },
      });

      queryClient.refetchQueries({
        queryKey: ["tables"],
        type: "all",
      });
    },
  });

  const toggleEditing = () => {
    setIsEditingName((prevState) => !prevState);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      updateName();
    } else if (event.key === "Escape") {
      toggleEditing();
      setNewName(tableName);
    }
  };
  const updateName = async () => {
    if (newName === tableName || !newName.trim()) {
      toggleEditing();
      return;
    }
    await mutateAsync(newName);
    toggleEditing();
  };

  useEffect(() => {
    setNewName(tableName);
  }, [tableName]);

  const inputWidth = tableName?.length * 10 + 100 + "px";

  const totalFilterApplied = filters?.length || 0;

  return (
    <Box className="flex items-center">
      <IconButton
        aria-label="back"
        variant="ghost"
        className="!rounded-full"
        icon={<Icons.ArrowLeftIcon size={24} />}
        onClick={() =>
          navigate({
            to: "/",
          })
        }
      />
      {/* TODO: Add Workspace */}
      {/* <Popover placement="right" closeOnEsc autoFocus>
        <PopoverTrigger>
          <IconButton
            aria-label="Workspace"
            variant="ghost"
            icon={<PiCaretRight size={24} />}
          />
        </PopoverTrigger>
        <PopoverContent>
          <TableList variant="mini" />
        </PopoverContent>
      </Popover> */}
      {!isTablePreviewPage && isEditingName ? (
        <div
          style={{ width: inputWidth }}
          className="flex items-center gap-2 max-w-[500px] overflow-auto"
        >
          <InputGroup onKeyDown={handleKeyDown}>
            <Input
              colorScheme="purple"
              value={newName}
              onBlur={() => {
                updateName();
              }}
              onChange={(e) => setNewName(e.target.value)}
              autoFocus
              isDisabled={isPending}
              onFocus={(e) => e.target.select()}
            />
            <InputRightElement>
              {isPending ? (
                <Spinner size={"sm"} color="purple" />
              ) : (
                <IconButton
                  size="sm"
                  variant={"ghost"}
                  colorScheme="purple"
                  aria-label="save"
                  onClick={updateName}
                >
                  <Icons.CheckIcon size={20} />
                </IconButton>
              )}
            </InputRightElement>
          </InputGroup>
        </div>
      ) : (
        <Skeleton isLoaded={!!tableName}>
          <div className="flex items-center gap-1">
            <div className="flex items-center gap-2">
              <Icons.ViewTableIcon />
              <h3
                className="cursor-pointer rounded-md font-bold hover:bg-slate-200 truncate max-w-[200px]"
                onClick={toggleEditing}
              >
                {tableName}
                {totalFilterApplied > 0 && (
                  <span className="text-gray-500 text-xs font-semibold">
                    ( {filters?.length} Filter
                    {`${totalFilterApplied > 1 ? "s" : ""}`} Applied )
                  </span>
                )}
              </h3>
              {!isUserLoggedIn && isTablePreviewPage && (
                <span className="ml-2 rounded bg-[#F8F0FF] px-2 py-1 text-sm text-textPurple">
                  Create an account for free to view the entire list.
                </span>
              )}
            </div>
            {isPremium && <PremiumIcon />}
          </div>
        </Skeleton>
      )}
    </Box>
  );
};

export default TableName;
