import { useState } from "react";
import { Button, ButtonGroup, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import Swal from "sweetalert2";

import AccordionButton from "../Common/AccordionButton";
import SearchInputAccordion from "../Common/SearchInputAccordion";
import Icons from "../Icons";
import RadioButton from "../Common/RadioButton";
import { Option } from "../Common/MultiSelectDropdown/types";

import { filterValidObjKeys, generateId } from "@/utils";
import { useSearchLeadsStore } from "@/stores/searchleads.store";
import { IScraperCompanySearchPayload } from "@/types/leads.types";
import leadService from "@/services/leads.service";
import CompanyTypes from "./CompanyTypes";
import CompanySizes from "./CompanySizes";
import Countries from "./Countries";
import Industries from "./Industries";
import Revenue from "./Revenue";
import SearchPersanaInput from "../Common/PersanaInput/SearchPersanaInput";

const CompanySidebarComponent = ({
  isForAutoPilot = false,
}: {
  isForAutoPilot?: boolean;
}) => {
  const toast = useToast();
  const [isUseMixRank] = useState(true);
  const [inputtedValues, setInputtedValues] = useState({
    company_type: [],
    minimun_company_size: "",
    maximum_company_size: "",
    year_founded: "",
    isGreaterYearFounded: false,
    isSmallerYearFounded: false,
    name: "",
    company_name_prefix: "",
    website: "",
    followers: "",
    isMoreFollowers: false,
    isLessFollowers: false,
    revenue: null as Option | null,
    fromRevenue: null as Option | null,
    toRevenue: null as Option | null,
    isMoreRevenue: true,
    isLessRevenue: false,
    isRange: false,
    keyword: "",
    country_codes: [],
    industries: [],
    excluded_industries: [],
    company_size: null,
    company_sizes: [],
    company_types: [],
    city_to_include: "",
    city_to_exclude: "",
    //TODO: Add this
    //description_keywords: "",
  });

  const { mutateAsync } = useMutation({
    mutationFn: ({
      payload,
      params = {},
    }: {
      payload: IScraperCompanySearchPayload;
      params?: any;
    }) =>
      leadService.searchCompanyIscraper(
        payload,
        "leads/search-company-linkedin",
        params,
      ),
  });
  const updateLeadState = useSearchLeadsStore((state) => state.updateState);
  const searchingFor = useSearchLeadsStore((state) => state.searchingFor);

  console.log(searchingFor, "searchingFor");

  const handleInputChange = (
    e: any,
    stateToUpdate: any,
    setStateToUpdate: any,
  ) => {
    setStateToUpdate({
      ...stateToUpdate,
      [e.target.name]: e.target.value,
    });
  };

  const handleToggleSelect = (
    e: any,
    stateToUpdate: any,
    setStateToUpdate: any,
    rivalSelect: any,
  ) => {
    setStateToUpdate({
      ...stateToUpdate,
      [e.target.name]: !stateToUpdate[e.target.name],
      [rivalSelect]: false,
      isRange: false,
    });
  };

  const handlePushToArray = (
    value: any,
    stateToUpdate: any,
    setStateToUpdate: any,
  ) => {
    if (value?.name === "company_type" && value?.value?.length > 1) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You can only select one company type",
      });
      return;
    }
    setStateToUpdate({
      ...stateToUpdate,
      [value?.name]: value?.value,
    });
  };

  function compareValues(more: boolean, less: boolean, value: string) {
    if (!more && !less) {
      return `eq:${value}`;
    } else if (more) {
      return `gt:${value}`;
    } else if (less) {
      return `lt:${value}`;
    }
  }

  const handleSearch = async () => {
    updateLeadState({ searchingFor: "COMPANY" });
    const convertedInputtedData = {
      per_page: 100,
      company_sizes:
        inputtedValues?.company_sizes?.map(
          (company: Option) => company.value,
        ) || [],
      company_types:
        inputtedValues?.company_types?.map(
          (company: Option) => company.value,
        ) || [],
      //TODO: Update this
      // company_size: (inputtedValues?.company_size || [])
      //   ?.map((item: Option) => item?.value),
      year_founded: inputtedValues?.year_founded
        ? compareValues(
            inputtedValues?.isGreaterYearFounded,
            inputtedValues?.isSmallerYearFounded,
            inputtedValues?.year_founded,
          )
        : "",
      website: inputtedValues?.website,
      name: inputtedValues?.name,
      company_name_prefix: inputtedValues?.company_name_prefix,
      //TODO: Add this
      //description: inputtedValues?.description_keywords,
      followers: inputtedValues?.followers
        ? compareValues(
            inputtedValues?.isMoreFollowers,
            inputtedValues?.isLessFollowers,
            inputtedValues?.followers,
          )
        : "",
      revenue: inputtedValues.isRange
        ? `${inputtedValues.fromRevenue?.value}:${inputtedValues.toRevenue?.value}`
        : inputtedValues?.revenue
          ? compareValues(
              inputtedValues?.isMoreRevenue,
              inputtedValues?.isLessRevenue,
              inputtedValues?.revenue?.value,
            )
          : "",
      keyword: inputtedValues?.keyword,
      country_codes: inputtedValues?.country_codes?.map(
        (item: Option) => item?.value,
      ),
      industry_ids: inputtedValues?.industries?.map(
        (item: Option) => +item?.value,
      ),
      excluded_industry_ids: inputtedValues?.excluded_industries?.map(
        (item: Option) => +item?.value,
      ),
      city_to_include: inputtedValues?.city_to_include,
      city_to_exclude: inputtedValues?.city_to_exclude,
    };

    const validInputtedData = filterValidObjKeys(convertedInputtedData);

    if (Object.keys(validInputtedData || {}).length === 0) {
      toast({
        title: "No data to search",
        description: "Please input some data to search",
        status: "error",
        isClosable: true,
        duration: 2000,
        position: "top-right",
      });
      return;
    }

    await mutateAsync(
      {
        payload: validInputtedData,
        ...(isUseMixRank ? { params: { isUseMixrank: true } } : {}),
      },
      {
        onSuccess: (response) => {
          updateLeadState({
            currentSearchPayload: validInputtedData,
            searchingFor: "",
            searchLeadsResult: {
              profiles: response?.data.profiles || [],
              // @ts-ignore
              pagination: {
                ...response?.data.pagination,
                // @ts-ignore
                page: response?.data?.pagination?.current_page,
              } || {
                total_entries: 0,
                total_pages: 0,
                page: 1,
                per_page: 100,
              },
              apiPath: `leads/search-company-linkedin?per_page=${response?.data?.providerName === "iScraper" ? 100 : 25}&want_to_import=true&providerName=${response?.data?.providerName}${
                response?.data?.isUseMixrank ? "&isUseMixrank=true" : ""
              }`,
              columsMapping: response?.data?.columsMapping || [],
            },
          });
          console.log(response, "response");
        },
        onError: (error) => {
          updateLeadState({ searchingFor: "" });
          console.log(error, "error");
        },
      },
    );
  };

  return (
    <div className=" flex size-full h-full max-h-screen flex-col justify-between">
      <div
        className={`grow overflow-y-auto bg-white ${isForAutoPilot ? "h-full" : "h-[5rem]"}`}
      >
        {/* Keyword */}
        <SearchInputAccordion
          defaultIndex={0}
          button={
            <AccordionButton
              icon={<Icons.FileSearchIcon className="text-lg" color="grey" />}
            >
              Keywords
            </AccordionButton>
          }
          numOfAppliedFilters={inputtedValues?.keyword ? 1 : 0}
        >
          <div className="space-y-3">
            <SearchPersanaInput
              value={inputtedValues?.keyword}
              placeholder={""}
              name={"keyword"}
              title={"Keywords"}
              onChange={(e) => {
                handleInputChange(e, inputtedValues, setInputtedValues);
              }}
              type="text"
              description="If a keyword is provided, we will return results that match the given keyword. The match can be partial or complete. This allows you to effectively search and filter through the results based on specific search terms or phrases."
            />
          </div>
        </SearchInputAccordion>

        {/* Companies */}
        <SearchInputAccordion
          button={
            <AccordionButton
              icon={<Icons.FaceIcon className="text-lg" color="grey" />}
            >
              Companies{" "}
            </AccordionButton>
          }
          numOfAppliedFilters={
            (inputtedValues?.name ? 1 : 0) +
            (inputtedValues?.website ? 1 : 0) +
            (inputtedValues?.company_types?.length || 0) +
            (inputtedValues?.company_sizes?.length || 0) +
            (inputtedValues?.year_founded ? 1 : 0) +
            (inputtedValues?.followers ? 1 : 0) +
            (inputtedValues?.revenue ? 1 : 0) +
            (inputtedValues?.company_name_prefix ? 1 : 0)
          }
        >
          <div className="space-y-3">
            <SearchPersanaInput
              value={inputtedValues?.name}
              placeholder={"e.g. google, facebook"}
              name={"name"}
              title={"Company Name"}
              onChange={(e) => {
                handleInputChange(e, inputtedValues, setInputtedValues);
              }}
              type="text"
            />

            <SearchPersanaInput
              value={inputtedValues?.website}
              placeholder={"e.g. example.com, example.org"}
              name={"website"}
              title={"Company Website"}
              onChange={(e) => {
                handleInputChange(e, inputtedValues, setInputtedValues);
              }}
              type="text"
            />

            {/* TODO: Add this */}
            {/* <PersanaInput
              value={inputtedValues?.description_keywords}
              placeholder={"e.g. sales, data, outbound"}
              name={"description_keywords"}
              label={"Include description keywords"}
              type="text"
              onChange={(e) => {
                handleInputChange(e, inputtedValues, setInputtedValues);
              }}
            /> */}
            <CompanyTypes
              value={inputtedValues.company_types}
              onChange={(selectedValues) => {
                handlePushToArray(
                  { name: "company_types", value: selectedValues },
                  inputtedValues,
                  setInputtedValues,
                );
              }}
            />
            <CompanySizes
              value={inputtedValues.company_sizes as unknown as Option}
              onChange={(selectedValues) => {
                handlePushToArray(
                  { name: "company_sizes", value: selectedValues },
                  inputtedValues,
                  setInputtedValues,
                );
              }}
            />
            <SearchPersanaInput
              value={inputtedValues?.year_founded}
              optionComponent={
                <div className="flex items-center gap-x-3">
                  <RadioButton
                    label={"GT"}
                    name={"isGreaterYearFounded"}
                    checked={inputtedValues?.isGreaterYearFounded}
                    setChecked={(e) => {
                      handleToggleSelect(
                        e,
                        inputtedValues,
                        setInputtedValues,
                        "isSmallerYearFounded",
                      );
                    }}
                  />
                  <RadioButton
                    label={"LT"}
                    name={"isSmallerYearFounded"}
                    checked={inputtedValues?.isSmallerYearFounded}
                    setChecked={(e) => {
                      handleToggleSelect(
                        e,
                        inputtedValues,
                        setInputtedValues,
                        "isGreaterYearFounded",
                      );
                    }}
                  />
                </div>
              }
              placeholder={"e.g. 1999"}
              name={"year_founded"}
              title={"Founded On"}
              onChange={(e) => {
                handleInputChange(e, inputtedValues, setInputtedValues);
              }}
              type="number"
            />
            <SearchPersanaInput
              value={inputtedValues?.followers}
              optionComponent={
                <div className="flex items-center gap-x-3">
                  <RadioButton
                    label={"GT"}
                    name={"isMoreFollowers"}
                    checked={inputtedValues?.isMoreFollowers}
                    setChecked={(e) => {
                      handleToggleSelect(
                        e,
                        inputtedValues,
                        setInputtedValues,
                        "isLessFollowers",
                      );
                    }}
                  />
                  <RadioButton
                    label={"LT"}
                    name={"isLessFollowers"}
                    checked={inputtedValues?.isLessFollowers}
                    setChecked={(e) => {
                      handleToggleSelect(
                        e,
                        inputtedValues,
                        setInputtedValues,
                        "isMoreFollowers",
                      );
                    }}
                  />
                </div>
              }
              placeholder={"e.g. 1000"}
              name={"followers"}
              title={"Followers"}
              onChange={(e) => {
                handleInputChange(e, inputtedValues, setInputtedValues);
              }}
              type="number"
            />

            <div>
              <div className="flex justify-between pb-2">
                <p className="font-medium">Revenue</p>
                <div className="flex items-center gap-x-3">
                  <RadioButton
                    label={"GT"}
                    name={"isMoreRevenue"}
                    checked={inputtedValues?.isMoreRevenue}
                    setChecked={(e) => {
                      handleToggleSelect(
                        e,
                        inputtedValues,
                        setInputtedValues,
                        "isLessRevenue",
                      );
                    }}
                  />
                  <RadioButton
                    label={"LT"}
                    name={"isLessRevenue"}
                    checked={inputtedValues?.isLessRevenue}
                    setChecked={(e) => {
                      handleToggleSelect(
                        e,
                        inputtedValues,
                        setInputtedValues,
                        "isMoreRevenue",
                      );
                    }}
                  />
                  <RadioButton
                    label={"Range"}
                    name={"isRange"}
                    checked={inputtedValues?.isRange}
                    setChecked={() => {
                      setInputtedValues((prevState) => ({
                        ...prevState,
                        isRange: !prevState.isRange,
                        isMoreRevenue: false,
                        isLessRevenue: false,
                      }));
                    }}
                  />
                </div>
              </div>
              {inputtedValues?.isRange ? (
                <div className="grid grid-cols-2 gap-x-2">
                  <Revenue
                    value={inputtedValues.fromRevenue as unknown as Option}
                    onChange={(value) => {
                      setInputtedValues({
                        ...inputtedValues,
                        // @ts-expect-error
                        fromRevenue: value,
                      });
                    }}
                  />
                  <Revenue
                    value={inputtedValues.toRevenue as unknown as Option}
                    onChange={(value) => {
                      setInputtedValues({
                        ...inputtedValues,
                        // @ts-expect-error
                        toRevenue: value,
                      });
                    }}
                  />
                </div>
              ) : (
                <Revenue
                  value={inputtedValues.revenue as unknown as Option}
                  onChange={(selectedValue) => {
                    setInputtedValues({
                      ...inputtedValues,
                      // @ts-expect-error
                      revenue: selectedValue,
                    });
                  }}
                />
              )}
            </div>
            <SearchPersanaInput
              value={inputtedValues?.company_name_prefix}
              placeholder={"e.g. google, facebook"}
              name={"company_name_prefix"}
              title={"Company Name Prefix"}
              onChange={(e) => {
                handleInputChange(e, inputtedValues, setInputtedValues);
              }}
              type="text"
            />
          </div>
        </SearchInputAccordion>

        {/* Locations */}
        <SearchInputAccordion
          button={
            <AccordionButton
              icon={<Icons.LocationOnIcon className="text-lg" color="grey" />}
            >
              Locations
            </AccordionButton>
          }
          numOfAppliedFilters={
            (inputtedValues?.country_codes?.length || 0) +
            (inputtedValues?.city_to_include ? 1 : 0) +
            (inputtedValues?.city_to_exclude ? 1 : 0)
          }
        >
          <div className="space-y-3">
            <Countries
              label="Select one or more countries"
              value={inputtedValues.country_codes as unknown as Option}
              onChange={(selectedValues) => {
                handlePushToArray(
                  { name: "country_codes", value: selectedValues },
                  inputtedValues,
                  setInputtedValues,
                );
              }}
            />
            {isUseMixRank && (
              <>
                <SearchPersanaInput
                  value={inputtedValues?.city_to_include}
                  placeholder={"e.g. San Francisco"}
                  name={"city_to_include"}
                  title={"Include city or state"}
                  onChange={(e) => {
                    handleInputChange(e, inputtedValues, setInputtedValues);
                  }}
                  type="text"
                />
                <SearchPersanaInput
                  value={inputtedValues?.city_to_exclude}
                  placeholder={"e.g. New York"}
                  name={"city_to_exclude"}
                  title={"Exclude city or state"}
                  onChange={(e) => {
                    handleInputChange(e, inputtedValues, setInputtedValues);
                  }}
                  type="text"
                />
              </>
            )}
          </div>
        </SearchInputAccordion>

        {/* Industries */}
        <SearchInputAccordion
          button={
            <AccordionButton
              icon={<Icons.IndustryIcon className="text-lg" color="grey" />}
            >
              Industries
            </AccordionButton>
          }
          numOfAppliedFilters={
            (inputtedValues?.industries?.length || 0) +
            (inputtedValues?.excluded_industries?.length || 0)
          }
        >
          <Industries
            value={inputtedValues.industries as unknown as Option}
            onChange={(selectedValues) => {
              handlePushToArray(
                { name: "industries", value: selectedValues },
                inputtedValues,
                setInputtedValues,
              );
            }}
            isCreatable={false}
          />
          <Industries
            value={inputtedValues.excluded_industries as unknown as Option}
            onChange={(selectedValues) => {
              handlePushToArray(
                { name: "excluded_industries", value: selectedValues },
                inputtedValues,
                setInputtedValues,
              );
            }}
            label="Excluded Industries"
            isCreatable={false}
          />
        </SearchInputAccordion>
      </div>
      {!isForAutoPilot && (
        <ButtonGroup className="!bg-white p-4 !font-title">
          <Button
            // isDisabled={totalFilters <= 0}
            isLoading={searchingFor === "COMPANY"}
            id={generateId("persana-company-search-button")}
            loadingText="Searching"
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            className={`!w-full !rounded-[6px] !bg-[#5024DC] !px-4 !py-[15px] !font-title !text-sm !font-semibold !tracking-wide !text-white`}
            onClick={() => {
              handleSearch();
            }}
          >
            Company Search
          </Button>
          {/* <IconButton
            colorScheme="gray"
            // isDisabled={totalFilters <= 0}
            aria-label="Save Filters"
            icon={<Icons.SaveIcon />}
            onClick={() => {
              saveFilterModalDisclosure.onOpen();
            }}
          /> */}
        </ButtonGroup>
      )}
    </div>
  );
};

export default CompanySidebarComponent;
