import { RowData } from "@/types/table.types";
import { GridApi } from "ag-grid-community";
import { create } from "zustand";

interface ITableSearchStore {
  searchText: string;
  searchedCells: { rowIndex: number; field: string }[];
  searchIndex: number;
  isSearching: boolean;
  matchCount: number;
  rowsData: RowData[];
  gridApi: GridApi | null;
  setSearchText: (searchText: string) => void;
  setSearchedCells: (
    searchedCells: { rowIndex: number; field: string }[],
  ) => void;
  setSearchIndex: (searchIndex: number) => void;
  setIsSearching: (isSearching: boolean) => void;
  setMatchCount: (matchCount: number) => void;
  updateStore: (update: Partial<ITableSearchStore>) => void;
}
export const useTableSearchStore = create<ITableSearchStore>((set) => ({
  searchText: "",
  searchedCells: [],
  searchIndex: -1,
  isSearching: false,
  searchingFor: "",
  matchCount: 0,
  rowsData: [],
  gridApi: null,
  setSearchText: (searchText) => set({ searchText }),
  setSearchedCells: (searchedCells) => set({ searchedCells }),
  setSearchIndex: (searchIndex) => set({ searchIndex }),
  setIsSearching: (isSearching) => set({ isSearching }),
  setMatchCount: (matchCount) => set({ matchCount }),
  updateStore: (update) => set(update),
}));
