import { useToast } from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import ReactSelect, {
  components as defaultComponents,
  GroupBase,
  SelectComponentsConfig,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import Menu from "./Menu";
import { customStyles } from "./config";
import type { Option } from "./types";

interface Props {
  isCreateAble?: boolean;
  options: Option[];
  htmlNameAttribute?: string;
  placeholder?: string;
  isMulti?: boolean;
  onChange: (selected: Option[] | Option) => void;
  value?: Option[] | Option | null;
  limit?: number;
  closeMenuOnSelect?: boolean;
  isInlined?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  components?: SelectComponentsConfig<Option, boolean, GroupBase<Option>>;
}

const MultiSelectDropdown = ({
  isCreateAble = true,
  options = [],
  htmlNameAttribute = "",
  isMulti = true,
  onChange,
  value = [],
  placeholder,
  limit = 0,
  closeMenuOnSelect = false,
  isInlined = false,
  isDisabled = false,
  isLoading = false,
  components,
}: Props) => {
  const toast = useToast();
  const SelectComponent = isCreateAble ? CreatableSelect : ReactSelect;
  const [inputValue, setInputValue] = useState("");
  const dropdownRef = useRef<HTMLDivElement | null>(null); // To track the focus on the dropdown

  const Noop = () => null;

  const createOption = (inputValue: string): Option => ({
    label: inputValue,
    value: inputValue,
    _isNew: true,
  });

  const handleKeyDown = async (event: KeyboardEvent) => {
    if (!dropdownRef.current) return;

    const isCtrlOrCmdPressed = event.ctrlKey || event.metaKey;

    if (isCtrlOrCmdPressed && event.key === "c" && Array.isArray(value)) {
      event.preventDefault();
      const copiedText = value.map((item) => item.label).join(", ");
      await navigator.clipboard.writeText(copiedText);
      toast({
        title: "Copied!",
        description: "Selected items copied to clipboard.",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }

    if (isCtrlOrCmdPressed && event.key === "v") {
      event.preventDefault();
      try {
        const text = await navigator.clipboard.readText();
        const pastedOptions = text
          .split(",")
          .map((item) => createOption(item.trim()));

        const newValue = isMulti
          ? [...(value as Option[]), ...pastedOptions]
          : pastedOptions[0];

        onChange(newValue);

        toast({
          title: "Pasted!",
          description: "Items pasted from clipboard.",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      } catch (err) {
        toast({
          title: "Error",
          description: "Failed to paste from clipboard.",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
  };

  useEffect(() => {
    const currentDropdown = dropdownRef.current;
    if (currentDropdown) {
      currentDropdown.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (currentDropdown) {
        currentDropdown.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [value, isMulti, options]);

  return (
    <div ref={dropdownRef}>
      <SelectComponent
        value={value}
        isDisabled={isDisabled}
        isMulti={isMulti}
        options={options}
        isLoading={isLoading}
        isClearable
        placeholder={placeholder || "Select items from the dropdown or type"}
        noOptionsMessage={() =>
          isInlined
            ? ""
            : isCreateAble
              ? "Write something and press enter"
              : "No options available"
        }
        menuPortalTarget={document.body}
        getNewOptionData={(inputValue) => {
          return createOption(inputValue);
        }}
        components={
          {
            ...(components as any),
            Menu: isInlined ? Noop : Menu,
            DropdownIndicator: isInlined
              ? Noop
              : (props: any) => (
                  <defaultComponents.DropdownIndicator {...props} />
                ),
          } as any
        }
        name={htmlNameAttribute}
        styles={{
          ...(customStyles as any),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (base) => ({ ...base, maxHeight: "320px" }),
          placeholder: (base) => ({ ...base, textAlign: "left" }),
        }}
        onMenuClose={() => {
          if (limit > 0 && Array.isArray(value) && value.length > limit) {
            toast({
              position: "top-right",
              title: `You can only select up to ${limit} items.`,
              status: "error",
              duration: 2000,
              isClosable: true,
            });
            return;
          }

          if (inputValue && isCreateAble) {
            if (
              value &&
              Array.isArray(value) &&
              !options.find((item) => item.value === inputValue) &&
              !value?.find((item) => item.value === inputValue)
            ) {
              onChange([...value, createOption(inputValue)]);
            }
          }
        }}
        onInputChange={(input) => setInputValue(input)}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={(selected) => {
          if (limit > 0 && Array.isArray(selected) && selected.length > limit) {
            toast({
              position: "top-right",
              title: `You can only select up to ${limit} items.`,
              status: "error",
              duration: 2000,
              isClosable: true,
            });
            return;
          }
          if (isMulti) {
            onChange(selected as Option[]);
          } else {
            onChange(selected as Option);
          }
          setInputValue("");
        }}
      />
    </div>
  );
};

export default MultiSelectDropdown;
