import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  InputGroup,
  InputRightElement,
  Select,
  UseDisclosureReturn,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useFilterManager, Filter } from "./useFilterManager";

interface SaveSearchModalProps {
  currentFilter: Filter;
  disclosure: UseDisclosureReturn;
}

export const SaveSearchModal = ({
  disclosure: { isOpen, onClose },
  currentFilter,
}: SaveSearchModalProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { save, update, list, isSaving } = useFilterManager();

  const [searchName, setSearchName] = useState(currentFilter.name || "");
  const [selectedSearch, setSelectedSearch] = useState(-1);
  const [error, setError] = useState("");

  useEffect(() => {
    if (currentFilter) {
      setSearchName(
        currentFilter.name || currentFilter.naturalFilters?.query_title || "",
      );
    }
  }, [currentFilter]);

  const handleSave = async () => {
    const existingFilters = list();
    const existingFilterWithName = existingFilters.some(
      (filter) => filter.name?.toLowerCase() === searchName.toLowerCase(),
    );

    if (existingFilterWithName) {
      setError(
        "A filter with this name already exists. Please choose another name.",
      );
      return;
    }

    setError("");
    const filter: Filter = {
      ...currentFilter,
      name: searchName || currentFilter.name,
      sideBarInput: currentFilter.sideBarInput,
      currentSearchPayload: currentFilter.currentSearchPayload,
      assistantInput: currentFilter.assistantInput,
      naturalFilters: currentFilter.naturalFilters,
    };

    await save(filter);
    handleClose();
  };

  const handleAIIconClick = () => {
    const aiGeneratedName =
      currentFilter?.naturalFilters?.query_title || "Untitled AI Search";
    setSearchName(aiGeneratedName);
  };

  const handleUpdate = async () => {
    if (isNaN(+selectedSearch) || +selectedSearch < 0) return;
    const existing = list().at(Number(selectedSearch));
    await update(Number(selectedSearch), {
      ...currentFilter,
      name:
        searchName ||
        currentFilter.name ||
        existing?.name ||
        currentFilter.naturalFilters?.query_title ||
        "Untitled",
      sideBarInput: currentFilter.sideBarInput,
      currentSearchPayload: currentFilter.currentSearchPayload,
      assistantInput: currentFilter.assistantInput,
      naturalFilters: currentFilter.naturalFilters,
    });
    handleClose();
  };

  const handleClose = () => {
    setSearchName("");
    setSelectedSearch(-1);
    setError("");
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Save a new search</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs
              variant="line"
              size="sm"
              tabIndex={tabIndex}
              onChange={setTabIndex}
            >
              {" "}
              <TabList mb="1em">
                <Tab>Save as a new search</Tab>
                <Tab>Update existing search</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <InputGroup>
                    <Input
                      autoFocus
                      onFocus={(e) => e.target.select()}
                      mt={4}
                      placeholder="Enter new search name"
                      value={searchName}
                      onChange={(e) => setSearchName(e.target.value)}
                    />

                    <InputRightElement
                      pointerEvents={
                        currentFilter?.naturalFilters?.query_title
                          ? "auto"
                          : "none"
                      }
                      opacity={
                        currentFilter?.naturalFilters?.query_title ? 1 : 0.2
                      }
                      mt={4}
                      onClick={handleAIIconClick}
                      cursor="pointer"
                    >
                      <IoSparklesSharp color="purple" />
                    </InputRightElement>
                  </InputGroup>
                  {error && (
                    <Text color="red.500" mt={2} fontSize={"xs"}>
                      {error}
                    </Text>
                  )}
                </TabPanel>

                <TabPanel>
                  <Select
                    placeholder="Select Existing Search"
                    mt={4}
                    value={
                      typeof selectedSearch === "number"
                        ? String(selectedSearch)
                        : ""
                    }
                    onChange={(e) => setSelectedSearch(+e.target.value)}
                  >
                    {list().map((filter, index) => (
                      <option key={index} value={index}>
                        {filter.name ??
                          filter.naturalFilters?.query_title ??
                          "Untitled"}
                      </option>
                    ))}
                  </Select>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={handleClose} isDisabled={isSaving}>
              Cancel
            </Button>
            {tabIndex === 0 ? (
              <Button
                className="!bg-primary-gradient"
                colorScheme="blue"
                ml={3}
                onClick={() => handleSave()}
                isLoading={isSaving}
                isDisabled={isSaving || !searchName}
              >
                Save search
              </Button>
            ) : (
              <Button
                className="!bg-primary-gradient"
                colorScheme="blue"
                ml={3}
                onClick={() => handleUpdate()}
                isLoading={isSaving}
                isDisabled={isSaving || selectedSearch < 0}
              >
                Update search
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
import { useSearchLeadsStore } from "../store";
import { IoSparklesSharp } from "react-icons/io5";

export const SaveCurrentFilter = ({
  disclosure,
}: {
  disclosure: UseDisclosureReturn;
}) => {
  const currentFilter: Filter = useSearchLeadsStore((state) => ({
    naturalFilters: state.naturalFilters,
    expandedJobTitles: state.expandedJobTitles,
    sideBarInput: state.sideBarInput,
    currentSearchPayload: state.currentSearchPayload,
    assistantInput: state.assistantInput,
    valueLabels: state.valueLabels,
  }));

  return (
    <div>
      <SaveSearchModal disclosure={disclosure} currentFilter={currentFilter} />
    </div>
  );
};
