import questConfig from "@/constants/questConfig";
import MakeAPICall from "@/lib/apiCalls";
import { SubscriptionDetails, UserData } from "@/types/user.types";

class UserService {
  constructor(private apiCaller = MakeAPICall) {}

  async getUserResourceAccess(resourceType: string) {
    const apiCall = new this.apiCaller({
      apiPath: `users/resource-access-check/${resourceType}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async getAdminAccessCheck() {
    const apiCall = new this.apiCaller({
      apiPath: "users/admin-access-check",
    });
    const response = await apiCall.get();
    return response;
  }

  async getOtp(userInput: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/enter`,
      payload: { email: userInput },
    });
    const response = await apiCall.post();
    return response;
  }

  async verifyOtp(email: string, value: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/verify/email-otp`,
      payload: { email, otp: value },
    });
    const response = await apiCall.post();
    return response;
  }
  async getUser(
    fields: Array<keyof UserData> = [
      "_id",
      "creditLeft",
      "creditUsed",
      "profileInfo",
      "useSelfOpenAIKey",
      "planType",
      "Email",
      "token",
      "role",
      "createdAt",
      "jobChangeTracker",
      "findPeoplePreference",
      "joyrideInfo",
      "isFreeCreditBlocked",
    ],
  ) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_BE_BASE_URL}/api/v1/users/details`,
      params: { fields: fields.join(",") },
    });
    const response = await apiCall.get();
    if (response) {
      return response?.data as Pick<UserData, (typeof fields)[number]>;
    } else {
      return {} as any;
    }
  }

  async getUserCreditDetails() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_BE_BASE_URL}/api/v1/users/details?fields=creditLeft,creditUsed,planType`,
    });
    const response = await apiCall.get<{
      creditLeft: number;
      creditUsed: number;
      planType: string;
      _id: string;
    }>();
    if (response) {
      return response;
    } else {
      return {};
    }
  }

  async refreshCredits(email: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/credit/details/${email}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async logout() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/web/logout`,
    });

    const response = await apiCall.get();
    return response;
  }

  async saveSmartleadAPIKey(apiKey: string, userId: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/saveSmartLeadKey`,
      payload: {
        data: {
          apiKey,
          userId: userId,
        },
      },
    });
    const response = await apiCall.post();
    return response;
  }

  async saveHubspotKey(apiKey: string, userId: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/saveHubspotKey`,
      payload: {
        data: {
          apiKey,
          userId: userId,
        },
      },
    });
    const response = await apiCall.post();
    return response;
  }

  async saveOpenAIKey(apiKey: string, userId: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/saveOpenAIKey`,
      payload: {
        data: {
          apiKey,
          userId: userId,
        },
      },
    });
    const response = await apiCall.post();
    return response;
  }

  async connectHubspot(userId: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/hubspot/code/${userId}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async disconnectHubspot(userId: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/clear-hubspot/${userId}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async connectSalesforce(userId: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/salesforce/code/${userId}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async updateQuestUserData(userId: string, token: string, name: string) {
    const response = await fetch(
      `${import.meta.env.VITE_QUEST_BACKEND_URL}/api/users/${userId}`,
      {
        method: "POST",
        headers: {
          apikey: import.meta.env.VITE_QUEST_API_KEY,
          token,
          userId,
        },
        body: JSON.stringify({
          name,
        }),
      },
    );
    await response.json();
  }

  async referralClaim(email: string) {
    const response = await fetch(
      `${import.meta.env.VITE_QUEST_BACKEND_URL}/api/users/external/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          apiKey: import.meta.env.VITE_QUEST_API_KEY,
          apisecret: import.meta.env.VITE_QUEST_API_SECRET,
          userId: import.meta.env.VITE_QUEST_ADMIN_ID,
          token: import.meta.env.VITE_QUEST_ADMIN_TOKEN,
        },
        body: JSON.stringify({
          externalUserId: email,
          entityId: import.meta.env.VITE_QUEST_ENTITY_ID,
          email: email,
        }),
      },
    );
    const data = await response.json();
    return data;
  }

  async claimCredits(userId: string, token: string, referralCode: string) {
    const response = await fetch(
      `${import.meta.env.VITE_QUEST_BACKEND_URL}/api/v2/entities/${import.meta.env.VITE_QUEST_ENTITY_ID}/campaigns/${questConfig.REFERRAL_SCREN_ID}/claim`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
          apiKey: import.meta.env.VITE_QUEST_API_KEY,
          apisecret: import.meta.env.VITE_QUEST_API_SECRET,
          userId: userId,
          token: token,
        },
        body: JSON.stringify({
          userId,
          referralCode: referralCode,
          campaignVariationId: import.meta.env.VITE_QUEST_CAMPAIGN_VARIATION_ID,
        }),
      },
    );
    const data = await response.json();
    return data;
  }

  async updateUserProfileData(url: string, email: string, info: any) {
    const apiCall = new this.apiCaller({
      url,
      payload: { email, info },
    });
    const response = await apiCall.post();
    return response;
  }

  async generateUserCompanyDescription(url: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/scrape`,
      payload: { url },
    });
    const response = await apiCall.post();
    return response;
  }

  async updateProfileInfo({ email, info }: { email: string; info: any }) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/updateProfileInfo`,
      payload: { email, info },
    });
    const response = await apiCall.post();
    return response;
  }

  async updateJobChangeTrackerInfo({
    email,
    info,
  }: {
    email: string;
    info: any;
  }) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/updateJobChangeTrackerInfo`,
      payload: { email, info },
    });
    const response = await apiCall.post();
    return response;
  }

  async getuserList({
    limit,
    page,
    search,
  }: {
    limit: number;
    page: number;
    search: string;
  }) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/admin`,
      payload: { limit, page, search },
    });
    const response = await apiCall.post();
    return response;
  }

  async deleteUser(id: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/admin/${id}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async getSubscriptionLink(priceId: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/getSubscriptionLink/${priceId}?redirectUrl=${window.location.href}`,
    });
    const response = await apiCall.get<{
      link: string;
    }>();
    return response;
  }

  async userLogin(email: string, token: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/verify-admin-login?userEmail=${email}&token=${token}`,
    });
    const response = await apiCall.get();
    return response;
  }

  async getWhitelistedUsers() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/whitelisted`,
    });
    const response = await apiCall.get<{
      success: boolean;
      message: string;
    }>();
    return response;
  }

  async checkIsWhitelisted() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/is-whitelisted`,
    });
    const response = await apiCall.get();
    return response;
  }

  async updateWhitelistedUsers(email: string, action: "create" | "delete") {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/whitelisted/create-or-delete`,
      payload: { email, action },
    });
    const response = await apiCall.post();
    return response;
  }

  async getUserDetailsForAdmins(email: string) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/admin/get-user-details`,
      payload: { email },
    });
    const response = await apiCall.post();
    return response;
  }
  async updateUserCredits(email: string, credits: number) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/users/admin/update-credits`,
      payload: { email, credits },
    });
    const response = await apiCall.put();
    return response;
  }

  async userOnboardingAlert() {
    const apiCall = new this.apiCaller({
      apiPath: "users/userOnboardingAlert",
    });
    const response = await apiCall.get();
    return response;
  }

  async getSubscriptionDetails() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/subscription/plan-and-billing-details`,
    });

    const response = await apiCall.get<SubscriptionDetails>();

    return response;
  }

  async updateSubscriptionPlan({
    action,
    priceId,
  }: {
    action: string;
    priceId: string;
  }) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/subscription/update-plan`,
      payload: {
        action,
        priceId,
      },
    });
    const response = await apiCall.post();

    return response;
  }

  async cancelScheduledAction() {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/subscription/cancel-scheduled-action`,
    });
    const response = await apiCall.get();
    return response;
  }

  async generateTopUpCreditsLink({ credits }: { credits: string }) {
    const apiCall = new this.apiCaller({
      url: `${import.meta.env.VITE_OLD_BE_BASE_URL}/api/subscription/create-pop-up-session/${credits}`,
    });
    const response = await apiCall.get<{
      url: string;
    }>();
    return response;
  }

  async updateUserConfig(configId: string, config: object) {
    const apiCall = new this.apiCaller({
      apiPath: `users/config/update/${configId}`,
      payload: config,
    });
    const response = await apiCall.put();
    return response;
  }

  async updateUser(updatedFields: any) {
    const apiCall = new this.apiCaller({
      apiPath: `users/update`,
      payload: updatedFields,
    });
    const response = await apiCall.put();
    return response;
  }

  async updateUserDetails(body: Record<string, any>) {
    const apiCall = new this.apiCaller({
      apiPath: "users/details",
      payload: body,
    });
    const response = await apiCall.patch();
    return response;
  }
}

const userService = new UserService();
export default userService;
