import {
  IntegrationCategoies,
  IntegrationCategoryType,
} from "../Enrichment/constants";

const Sidebar = ({
  activeCategory,
  handleCategory,
}: {
  activeCategory: string;
  handleCategory: (categoryType: IntegrationCategoryType) => void;
}) => {
  return (
    <div className="enrichment-modal w-[20%]">
      <p className="w-full px-[24px] py-[10px] text-[16px] font-medium text-[#0F0D1C]">
        Categories
      </p>
      <div className="mx-[20px] mb-[20px] flex flex-col gap-[10px] overflow-y-auto">
        {IntegrationCategoies.map((item) => (
          <div
            key={item}
            className={`flex w-full cursor-pointer items-center gap-[10px] rounded-[4px] px-[16px] py-[12px] ${
              item === activeCategory ? "bg-[#F5F3FF]" : "bg-[#F9F8F9]"
            }`}
            onClick={() => handleCategory(item)}
          >
            <p
              className={`text-[14px] font-medium leading-[16px] ${
                item === activeCategory
                  ? "font-[500] text-textPurple"
                  : "font-[400] text-black"
              } `}
            >
              {item}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
