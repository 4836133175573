import { FileRoute } from "@tanstack/react-router";

import AutoPilotPostEngagementWorkflow from "@/components/AutoPilotPostEnagagementWorkflow.tsx";
import { useAutoPilotAccessCheck } from "@/pages/hooks/autoPilot";

export const Route = new FileRoute(
  "/auto-pilot/post-engagement-workflow",
).createRoute({
  component: PostEngagementWorkflowPage,
  errorComponent: () => <div>Error</div>,
});

function PostEngagementWorkflowPage() {
  useAutoPilotAccessCheck();

  return <AutoPilotPostEngagementWorkflow />;
}

export default PostEngagementWorkflowPage;
