import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";

import LGMIntegration from "@/components/Integrations/LGMIntegration";

import { useProviderList } from "@/hooks/useProviderData";
import tableService from "@/services/table.service";
import { TableData } from "@/types/table.types";
import leadService from "@/services/leads.service";
import Icons from "@/components/Icons";
import MultiSelectDropdown from "../../MultiSelectDropdown";
import providerService from "@/services/providers.service";
import { useQuery } from "@tanstack/react-query";
import { Option } from "../../MultiSelectDropdown/types";

type Props = {
  isConfigured: boolean;
  isOpen: boolean;
  workflowType: string;
  tableData?: TableData;
  onClose: (isRefetch: boolean) => void;
};

const Configurations = ({
  isConfigured,
  isOpen,
  tableData,

  onClose,
}: Props) => {
  const toast = useToast();
  const { data: providerList } = useProviderList();
  const apiKey = providerList?.data?.find(
    (provider) => provider.name === "laGrowthMachineKey",
  );
  const [isLoading, setIsLoading] = useState(false);
  const [storeOnLGM, setStoreOnLGM] = useState(
    !!tableData?.metaData?.storeOnLGM,
  );
  const [selectedAudience, setSelectedAudience] = useState<Option | null>(null);
  const {
    data: audiencesData = [],
    refetch,
    isLoading: isLGMLoading,
    isFetching,
  } = useQuery({
    queryKey: ["lgmAudiences"],
    queryFn: async () => {
      const response = await providerService.getLgmAudiences();
      return response;
    },
    refetchOnMount: true,
  });

  const handleChange = (isChecked: boolean) => {
    setStoreOnLGM(isChecked);
  };

  const handleAutoPilotLGM = async () => {
    const payload = tableData?.metaData;
    return await leadService.runPostEngagements(
      // @ts-ignore
      payload,
      "send-leads-to-lgm",
      tableData?._id,
    );
  };

  const updatePreferences = async () => {
    if (!apiKey) {
      toast({
        title: "Error",
        description: "Please add your LGM API key.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    setIsLoading(true);

    const response = await tableService.updateTableDataV2(
      tableData?._id || "",
      {
        metaData: {
          storeOnLGM,
          audienceName: selectedAudience?.value,
        },
      },
    );

    if (response.success) {
      !isConfigured && storeOnLGM && (await handleAutoPilotLGM());
      toast({
        title: "Success",
        description: "Successfully updated the preferences.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      toast({
        title: "Error",
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    onClose(true);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isConfigured) {
      const audience = audiencesData?.find(
        (item) => item.value === tableData?.metaData?.audienceName,
      ) as Option;
      setStoreOnLGM(!!tableData?.metaData?.storeOnLGM);
      setSelectedAudience(audience);
    }
  }, [isConfigured, tableData, audiencesData]);

  return (
    <Modal
      size={"3xl"}
      isOpen={isOpen}
      onClose={() => {
        onClose(false);
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Autopilot - Storage</ModalHeader>
        <ModalCloseButton />
        <ModalBody className=" max-h-[500px] overflow-y-auto custom-scrollbar">
          <div className="flex items-center justify-center flex-col mt-6 mb-4">
            <Text>Integrate LGM and seamlessly store your leads there.</Text>
          </div>

          <div className="w-[55%] mx-auto flex flex-col mb-4 gap-4">
            <div className="flex !h-[35px] items-center  !bg-white !text-sm">
              <label
                className="mr-2 cursor-pointer font-bold"
                htmlFor="auto-run-switch"
              >
                Store on LGM
              </label>
              <Switch
                isChecked={storeOnLGM}
                size="md"
                onChange={(e) => {
                  handleChange(e.target.checked);
                }}
                id="auto-run-switch"
              />
            </div>
            {/* Select or Create Audience */}
            <div className="mb-5 w-full">
              <div className="mb-1 flex items-center justify-between pb-0.5">
                <div className="flex items-center gap-2">
                  <p className="text-base font-semibold">Audience</p>
                  <Icons.RefreshIcon
                    size={19}
                    className={`cursor-pointer ${isLGMLoading || isFetching ? "animate-spin" : ""}`}
                    onClick={() => {
                      refetch();
                    }}
                  />
                </div>
              </div>
              <MultiSelectDropdown
                isMulti={false}
                placeholder="Create or Choose Audience"
                closeMenuOnSelect
                options={audiencesData}
                value={selectedAudience}
                onChange={(selectedOption) =>
                  setSelectedAudience(selectedOption as Option)
                }
              />
            </div>
            <LGMIntegration />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            size={"sm"}
            mr={3}
            onClick={() => onClose(false)}
          >
            Close
          </Button>
          <Button
            colorScheme="blue"
            variant="outline"
            size={"sm"}
            isLoading={isLoading}
            onClick={updatePreferences}
          >
            {isConfigured ? "Update" : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Configurations;
