import ProviderControls from "@/components/Common/AutoPilot/ProviderControls";
import {
  WalkThroughModalBody,
  WalkThroughModalFooter,
} from "@/components/Common/AutoPilot/WalkThroughModal";

function FindLeadsAutomations({ totalSteps }: { totalSteps: number }) {
  return (
    <>
      <WalkThroughModalBody>
        <div className="h-full w-full py-4 space-x-4">
          <ProviderControls
            title="Store on Hubspot"
            bgColor="#FFF1EA"
            description="Add your Hubspot key"
            imgSrc="https://persana-assets.s3.us-west-1.amazonaws.com/hubspot-logo.png"
            info="Integrate Hubspot and seamlessly store your leads there."
            providerName="hubspotApiKey"
          />
        </div>
      </WalkThroughModalBody>
      <WalkThroughModalFooter
        isLoading={false}
        onSave={async () => {}}
        totalSteps={totalSteps}
      />
    </>
  );
}

export default FindLeadsAutomations;
