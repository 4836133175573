import { useState } from "react";
import { Switch } from "@headlessui/react";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";

import WalkThroughInfo from "./WalkThroughModal/WalkThroughInfo";
import { ProviderNameType } from "@/stores/provider.store";
import { useProviderList } from "@/hooks/useProviderData";
import providerService from "@/services/providers.service";

interface Props {
  imgSrc: string;
  bgColor: string;
  info: string;
  title: string;
  description: string;
  providerName: ProviderNameType;
}

function ProviderControls({
  imgSrc,
  bgColor,
  info,
  title,
  description,
  providerName,
}: Props) {
  const [enabled, setEnabled] = useState(false);
  const [apiKeyValue, setAPIKeyValue] = useState("");

  const toast = useToast();
  const { data: providerList } = useProviderList();
  const apiKey = providerList?.data?.find(
    (provider) => provider.name === providerName,
  );

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: {
      apiKey: string;
      providerId?: string;
      providerName: string;
    }) => providerService.saveKey(payload),
    onError: (error) => {
      toast({
        title: error.message || "Something went wrong.",
        status: "error",
        isClosable: true,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        toast({
          title: response?.error || "Something went wrong.",
          status: "error",
          isClosable: true,
        });
      } else {
        toast({
          title: response?.data?.message || "Key saved successfully.",
          status: "success",
          isClosable: true,
        });
      }
    },
  });

  return (
    <div className="border-[#F0EFF1] border-b-[1.5px] space-y-4 px-4 py-5 font-poppins font-medium">
      <WalkThroughInfo info={info} className="px-0" />
      <div className="px-4 py-4 rounded-lg border border-[#BD90F4] flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div
            className={`w-6 h-6 rounded-md flex justify-center items-center`}
            style={{
              backgroundColor: bgColor,
            }}
          >
            <img src={imgSrc} className="w-[75%] h-auto shrink-0" />
          </div>
          <p className="">{title}</p>
        </div>
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={`${
            enabled ? "" : ""
          } relative inline-flex h-6 w-11 items-center rounded-full bg-[#DDD8FF]`}
          style={{
            boxShadow:
              "1px -1px 2px 0px #00000026 inset, -1px 1px 2px 0px #00000026 inset, 1px 1px 2px 0px #00000026 inset, -1px -1px 2px 0px #00000026 inset",
          }}
        >
          <span
            className={`${
              enabled ? "translate-x-[1.335rem]" : "translate-x-[0.16rem]"
            } inline-block h-5 w-5 transform rounded-full bg-white transition`}
            style={{
              boxShadow:
                "1px 1px 2px 0px #00000026, -1px -1px 2px 0px #00000026",
            }}
          />
        </Switch>
      </div>
      <p className="">{description}</p>
      <form
        onSubmit={() => {
          mutateAsync({
            apiKey: apiKeyValue?.trim(),
            providerId: apiKey?._id || "",
            providerName: providerName,
          });
        }}
        className="flex items-center w-full h-full"
      >
        <input
          type="text"
          placeholder={apiKey?.metaData?.accessToken || "********"}
          className="w-full py-4 px-4 border border-[#D0D4DC] flex-grow rounded-l-lg border-r-0 focus:outline-none focus:border-[#BD90F4] duration-200 disabled:bg-[#F9F8F9] disabled:border-[#F0EFF1]"
          disabled={!enabled}
          onChange={(e) => setAPIKeyValue(e.target.value)}
          value={apiKeyValue}
        />
        <button
          className="bg-[#DDD8FF] text-black py-4 px-4 border border-[#BD90F4] rounded-r-md disabled:cursor-not-allowed"
          disabled={!enabled || !apiKeyValue || isPending}
          type="submit"
        >
          {apiKey ? "Update" : "Save"}
        </button>
      </form>
    </div>
  );
}

export default ProviderControls;
