import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { TableData } from "@/types/table.types";
import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import leadService from "@/services/leads.service";
import { queryClient } from "@/lib/queryClient";
import tableService from "@/services/table.service";
import Icons from "@/components/Icons";

type Props = {
  isConfigured: boolean;
  isOpen: boolean;
  tableData?: TableData;
  onClose: (isRefetch: boolean) => void;
};

const frequencyList = [
  {
    label: "Daily",
    value: "1",
  },
  {
    label: "Weekly",
    value: "7",
  },
  {
    label: "Biweekly",
    value: "15",
  },
  {
    label: "Monthly",
    value: "30",
  },
];

const Configurations = ({
  isConfigured,
  isOpen,
  tableData,
  onClose,
}: Props) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [location, setLocation] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [limit, setLimit] = useState(20);
  const [frequencyToUpdate, setFrequencyToUpdate] = useState<Option | null>(
    null,
  );

  const otherPayloads = [
    {
      name: "Location",
      state: location,
      description: "",
      setState: (value: any) => {
        setLocation(value);
      },
    },
    {
      name: "Organization Name",
      state: organizationName,
      description: "",
      setState: (value: any) => {
        setOrganizationName(value);
      },
    },
    {
      name: "Number of jobs to fetch",
      state: limit,
      description: "Max limit is 100",
      setState: (value: any) => {
        setLimit(value);
      },
    },
  ];

  const handleAutoPilot = async () => {
    setIsLoading(true);
    const response = await leadService.runGoogleJobOpenings(
      {
        jobTitle,
        location,
        organizationName,
        limit,
        frequencyToUpdate: frequencyToUpdate?.value,
      },
      "create-job-openings-table",
    );
    queryClient.refetchQueries({
      queryKey: ["tables"],
    });
    window.open(`/table/${response?.data?.tableData?._id}`, "_blank");
    setIsLoading(false);
    onClose(true);
  };

  const handleTriggerFlow = async () => {
    setIsLoading(true);
    await leadService.runGoogleJobOpenings(
      {
        tableId: tableData?._id || "",
      },
      "update-job-openings-table",
    );
    window.open(`/table/${tableData?._id}`, "_blank");
    onClose(true);
    setIsLoading(false);
  };

  const updatePreferences = async () => {
    setIsLoading(true);
    const response = await tableService.updateTableDataV2(
      tableData?._id || "",
      {
        metaData: {
          jobTitle,
          location,
          organizationName,
          limit,
          frequencyToUpdate: frequencyToUpdate?.value,
        },
      },
    );
    if (response.success) {
      toast({
        title: "Success",
        description: "Successfully updated the preferences.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      if (jobTitle !== tableData?.metaData?.jobTitle) {
        handleTriggerFlow();
      }
    } else {
      toast({
        title: "Error",
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    onClose(true);
    setIsLoading(false);
  };

  useEffect(() => {
    const freq = frequencyList?.find(
      (item) => item.value === tableData?.metaData?.frequencyToUpdate,
    ) as Option;

    setJobTitle(tableData?.metaData?.jobTitle || "");
    setLocation(tableData?.metaData?.location || "");
    setOrganizationName(tableData?.metaData?.organizationName || "");
    setLimit(tableData?.metaData?.limit || 20);
    setFrequencyToUpdate(freq);
  }, [tableData]);

  return (
    <Modal
      size={"5xl"}
      isOpen={isOpen}
      onClose={() => onClose(false)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <p>Autopilot - Google Job Openings Preferences</p>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="custom-scrollbar max-h-[500px] min-h-[500px] overflow-y-auto">
          <VStack spacing={4} align={"stretch"} mt={4}>
            {isConfigured && (
              <Button
                colorScheme="purple"
                variant={"outline"}
                leftIcon={<Icons.RefreshIcon />}
                size={"sm"}
                className="w-fit"
                onClick={handleTriggerFlow}
                isLoading={isLoading}
              >
                Trigger Flow
              </Button>
            )}
            <VStack spacing={2} align="stretch">
              <Text fontSize="md" fontWeight="semibold">
                How frequently you want to find new jobs?
              </Text>
              <MultiSelectDropdown
                isMulti={false}
                placeholder="Frequency to find new jobs"
                closeMenuOnSelect
                options={frequencyList}
                value={frequencyToUpdate}
                onChange={(selected) =>
                  setFrequencyToUpdate(selected as Option)
                }
              />
            </VStack>
            <VStack spacing={1} align="stretch">
              <Text fontSize="md" fontWeight="semibold">
                Job title <span className="text-red-400">*</span>
              </Text>
              <Input
                placeholder="Enter Job Title"
                size="md"
                variant="outline"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                borderRadius="md"
                focusBorderColor="blue.400"
              />
            </VStack>
            {otherPayloads?.map((item) => (
              <VStack spacing={1} align="stretch">
                <Text fontSize="md" fontWeight="semibold">
                  {item.name}
                </Text>
                <Input
                  placeholder={`Enter ${item.name}`}
                  size="md"
                  variant="outline"
                  value={item.state}
                  onChange={(e) => item.setState(e.target.value)}
                  borderRadius="md"
                  focusBorderColor="blue.400"
                />
                <p className="pl-0.5 pt-0.5 font-title text-xs font-medium tracking-wide text-darkTextGray">
                  {item.description}
                </p>
              </VStack>
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            size={"sm"}
            mr={3}
            onClick={() => onClose(false)}
          >
            Close
          </Button>
          <Button
            colorScheme="blue"
            variant="outline"
            size={"sm"}
            isLoading={isLoading}
            isDisabled={!jobTitle || !frequencyToUpdate?.value}
            onClick={() =>
              isConfigured ? updatePreferences() : handleAutoPilot()
            }
          >
            {isConfigured ? "Update" : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Configurations;
