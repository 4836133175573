import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect } from "react";
import Select from "react-select";

import { useTableStore } from "@/stores/table.store";
import Icons from "../Icons";
import { useNavigate, useSearch } from "@tanstack/react-router";

interface Props {
  gridRef: React.RefObject<AgGridReact>;
}

const Pagination = ({ gridRef }: Props) => {
  const totalPages = useTableStore((state) => state.totalPages);
  const tableId = useTableStore((state) => state.tableData._id);
  const currentPage = useTableStore((state) => state.queryParams.pageNumber);
  const queryParams = useTableStore((state) => state.queryParams);
  const updateState = useTableStore((state) => state.updateState);
  const navigate = useNavigate({
    from: "/table/$tableId",
  });
  const expectedPage = useSearch({
    strict: false,
    select: (search: { page: number | "first" | "last" }) => {
      // Determines the page number based on the `page` parameter:
      // If `page` is "first", sets `pageNumber` to 1.
      // If `page` is "last", sets `pageNumber` to `totalPages`.
      // Otherwise, defaults to 1. Then navigates to the appropriate page.
      if (typeof search.page === "string") {
        let pageNumber: number;
        if (search.page === "first") {
          pageNumber = 1;
        } else if (search.page === "last") {
          pageNumber = totalPages;
        } else {
          pageNumber = 1;
        }
        navigate({
          replace: true,
          search: { page: pageNumber },
          params: {
            tableId: tableId,
          },
        });
        return pageNumber;
      }
      return search.page || 1;
    },
  });

  const handleChage = (type: string) => {
    // if (type === "next") {

    // } else {
    //   updateState({
    //     queryParams: {
    //       ...queryParams,
    //       pageNumber: currentPage - 1,
    //     },
    //   });
    // } updateState({
    //     queryParams: {
    //       ...queryParams,
    //       pageNumber: currentPage + 1,
    //     },
    //   });
    const page = type === "next" ? currentPage + 1 : currentPage - 1;
    updateState({
      queryParams: {
        ...queryParams,
        pageNumber: page,
      },
    });
    navigate({
      search: { page },
      params: {
        tableId: tableId,
      },
    });
    gridRef?.current?.api.redrawRows();
    gridRef?.current?.api.ensureIndexVisible(0, "top");
  };
  const changePage = useCallback(
    (page: number) => {
      updateState({
        queryParams: {
          ...queryParams,
          pageNumber: page,
        },
      });
      gridRef?.current?.api?.redrawRows();
      gridRef?.current?.api?.ensureIndexVisible(0, "top");
      navigate({
        search: { page },
        params: {
          tableId: tableId,
        },
      });
    },
    [updateState, queryParams, gridRef, navigate, tableId],
  );
  useEffect(() => {
    if (expectedPage && currentPage !== expectedPage) {
      changePage(expectedPage);
    }
  }, [expectedPage]);
  if (totalPages < 2) return null;

  const options = Array.from({ length: totalPages }, (_, i) => ({
    value: i + 1,
    label: String(i + 1),
  }));

  return (
    <div className="flex items-center gap-x-1.5">
      <PagginationButton
        type="prev"
        onClick={() => handleChage("prev")}
        disabled={currentPage === 1}
      />

      <div className="min-w-[50px]">
        <Select
          defaultValue={
            options.find((option) => option.value === queryParams.pageNumber) ||
            options[0]
          }
          options={options}
          value={
            options.find((option) => option.value === queryParams.pageNumber) ||
            options[0]
          }
          styles={{
            singleValue: (base) => ({
              ...base,
              textAlign: "center",
            }),
            input: (base) => ({
              ...base,
              caretColor: "transparent", // Hide the cursor
            }),
            option: (base) => ({
              ...base,
              whiteSpace: "nowrap", // Prevent text wrapping
            }),
          }}
          isSearchable
          isClearable={false}
          menuPosition="fixed"
          components={{
            DropdownIndicator: () => null,
          }}
          onChange={(option) => {
            if (option?.value) {
              changePage(option.value);
            }
          }}
        />
      </div>

      <PagginationButton
        type="next"
        onClick={() => handleChage("next")}
        disabled={currentPage === totalPages}
      />
    </div>
  );
};

export default React.memo(Pagination);

const PagginationButton = ({
  type,
  onClick,
  disabled,
}: {
  type: string;
  onClick: () => void;
  disabled: boolean;
}) => {
  return (
    <button
      className="text-[1.635rem] text-[#693DC7] duration-300 hover:text-primaryPink disabled:opacity-50"
      onClick={onClick}
      disabled={disabled}
    >
      {type === "next" ? (
        <Icons.LeftCircleArrow className="rotate-180" />
      ) : (
        <Icons.LeftCircleArrow />
      )}
    </button>
  );
};
