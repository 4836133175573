import React from "react";

interface Props {
  type: React.HTMLInputTypeAttribute;
  name: string;
  id?: string;
  placeholder?: string;
  value: string;
  title?: string;
  description?: string;
  optionComponent?: React.ReactNode;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function SearchPersanaInput({
  type,
  name,
  id,
  placeholder,
  value,
  onChange,
  title,
  description,
  optionComponent,
}: Props) {
  return (
    <div>
      <div>
        <p className="sticky mb-2 text-[14px] font-[600] text-[#1a202c] flex items-center justify-between space-x-3 w-full">
          <span>{title}</span>
          {optionComponent ? optionComponent : null}
        </p>
        {description && (
          <p className="block pb-1 font-title text-sm font-medium text-[#141313b2]">
            {description}
          </p>
        )}
      </div>

      <input
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="min-h-5 bg-[hsl(0,_0%,_100%)] border border-[hsl(0,_0%,_80%)] rounded text-sm py-2 px-[0.563rem] w-full text-[hsl(0,_0%,_20%)] placeholder:text-[hsl(0,_0%,_50%)]"
      />
    </div>
  );
}

export default SearchPersanaInput;
