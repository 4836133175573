import { FileRoute } from "@tanstack/react-router";

import { useAutoPilotAccessCheck } from "@/pages/hooks/autoPilot";
import AutoPilotPersanaJobOpeningsWorkflow from "@/components/AutoPilotPersanaJobsWorkflow";

export const Route = new FileRoute(
  "/auto-pilot/persana-job-openings-workflow",
).createRoute({
  component: PersanaJobsWorkflowPage,
  errorComponent: () => <div>Error</div>,
});

function PersanaJobsWorkflowPage() {
  useAutoPilotAccessCheck();

  return <AutoPilotPersanaJobOpeningsWorkflow />;
}

export default PersanaJobsWorkflowPage;
