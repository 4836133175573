import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { useState } from "react";

import ChatWithGPT from "@/components/AutoPilotLandingPage/ChatWithGPT";
import Header from "@/components/AutoPilotLandingPage/Header";
import PlaybooksSection from "@/components/AutoPilotLandingPage/PlaybooksSection";
import Triggers from "@/components/AutoPilotLandingPage/Triggers";
import useResourceAccessCheck from "@/pages/hooks/autoPilot";
import { getCurrentDateInYYYYMM } from "@/lib/utils";

const AutoPilotLandingPage = () => {
  const { isLoading, data: responseData } =
    useResourceAccessCheck("auto-pilot");

  const [isShowBookCallPopup, setIsShowBookCallPopup] = useState(false);

  const handleOpenBookCallPopup = () => {
    setIsShowBookCallPopup(true);
  };

  if (isLoading) {
    return (
      <div className="mt-5 flex justify-center">
        <Spinner size={"lg"} />
      </div>
    );
  }

  const hasAccess = !!responseData?.isAccess;

  return (
    <>
      <div className="flex flex-col">
        <Header />
        <div className="flex h-full gap-1 p-4">
          <div className="bg-gray-100 flex w-[65%] flex-col gap-4 p-4">
            {/* <Demo /> */}
            <Triggers
              hasAccess={hasAccess}
              handleOpenBookCallPopup={handleOpenBookCallPopup}
            />
          </div>

          <div className="bg-gray-200 flex w-[35%] flex-col gap-4 p-4">
            <ChatWithGPT
              hasAccess={hasAccess}
              handleOpenBookCallPopup={handleOpenBookCallPopup}
            />
            <PlaybooksSection />
          </div>
        </div>
      </div>
      <Modal
        isOpen={isShowBookCallPopup}
        onClose={() => {
          setIsShowBookCallPopup(false);
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody className="w-full flex-col items-center justify-items-center p-3">
            <p className="mb-2 text-lg">Book a call to get access</p>
            <Button
              size="sm"
              className="w-[150px] !bg-primary !text-white"
              onClick={() => {
                window.open(
                  `https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding?month=${getCurrentDateInYYYYMM()}`,
                  "_blank",
                );
              }}
            >
              Book
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AutoPilotLandingPage;
