import {
  Button,
  ButtonGroup,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
} from "@chakra-ui/react";
import Icons from "../Icons";
import { useNavigate } from "@tanstack/react-router";
import { getCurrentDateInYYYYMM } from "@/lib/utils";

import { useEffect, useState } from "react";
import AllEnrichments from "../Enrichment/AllEnrichments";
import {
  IntegrationCategoryType,
  Integrations,
  IntegrationsType,
} from "../Enrichment/constants";
import { useModalStore } from "@/stores/modal.store";

const MainContent = ({
  activeCategory,
}: {
  activeCategory: IntegrationCategoryType;
}) => {
  const navigate = useNavigate();
  const [selectedIntegration, setSelectedIntegration] =
    useState<IntegrationsType | null>();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredIntegrations, setFilteredIntegrations] =
    useState<IntegrationsType[]>(Integrations);
  const updateState = useModalStore((state) => state.updateState);

  const handleSearchQuery = (value: string) => {
    setSearchQuery(value);
    if (searchQuery) {
      setFilteredIntegrations(
        Integrations?.filter((item) =>
          item.title.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
        ),
      );
    } else {
      setFilteredIntegrations(Integrations);
    }
  };

  useEffect(() => {
    if (activeCategory === "All") {
      setFilteredIntegrations(Integrations);
    } else {
      const filtered = Integrations?.filter((item) =>
        item.categories?.includes(activeCategory),
      );
      setFilteredIntegrations(filtered);
    }
  }, [activeCategory]);

  return (
    <div className="flex w-[80%] border-l border-[#D0D4DC]">
      <div className="w-full">
        {/* Top-bar */}
        <div className="flex items-center justify-between gap-4 pt-[4px] pb-[10px] px-[20px]">
          {selectedIntegration ? (
            <Button
              size={"sm"}
              variant={"outline"}
              leftIcon={<Icons.BackIcon />}
              onClick={() => {
                setSelectedIntegration(null);
              }}
            >
              Go Back
            </Button>
          ) : (
            <InputGroup w={"60%"} size={"sm"}>
              <InputLeftElement>
                <Icons.SearchIcon size={20} color="gray" />
              </InputLeftElement>
              <Input
                style={{ borderRadius: "6px" }}
                placeholder="Search all integrations"
                variant="outline"
                value={searchQuery}
                onChange={(e) => handleSearchQuery(e.target.value)}
              />
            </InputGroup>
          )}

          <ButtonGroup>
            <Button
              size={"sm"}
              variant={"outline"}
              leftIcon={<Icons.SettingsIcon />}
              onClick={() => {
                updateState({
                  isOpenModal: false,
                  modalData: { modalType: "", metaData: "" },
                });
                navigate({ to: "/settings" });
              }}
            >
              Manage Integrations
            </Button>
            <Link
              href={`https://calendly.com/d/2xg-4vb-t3c/persana-ai-onboarding?month=${getCurrentDateInYYYYMM()}`}
              target="_blank"
            >
              <Button
                size={"sm"}
                className="!bg-[#F5F3FF] !text-[#5024DC]"
                leftIcon={<Icons.PlusIcon />}
              >
                Build Your Own App
              </Button>
            </Link>
          </ButtonGroup>
        </div>
        <Divider />
        {/* Providers List */}
        {!selectedIntegration ? (
          <div className="py-[20px] max-h-[540px] custom-scrollbar overflow-auto grid grid-cols-2 items-center gap-4 px-[20px]">
            {filteredIntegrations?.map((item: any) => (
              <div
                onClick={() => setSelectedIntegration(item)}
                className="min-h-[210px] flex flex-col justify-between cursor-pointer rounded-lg bg-white border border-[#D0D4DC] shadow-md"
              >
                <div className="flex flex-col p-4 gap-3 flex-grow">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <img
                        src={item.icon}
                        alt={item.title}
                        className="w-6 h-auto"
                      />
                      <p className="text-[16px] font-medium text-[#0F0D1C]">
                        {item.title}
                      </p>
                    </div>
                    <div className="px-[12px] py-[8px] bg-[#F5F3FF] text-[#5024DC] text-[14px] font-medium rounded-[10px]">
                      Integration
                    </div>
                  </div>
                  <div className="my-2">
                    <p className="text-[13px] font-normal text-[#7E889F] mt-1">
                      {item.description}
                    </p>
                  </div>
                </div>
                <div className="bg-[#F5F3FF] px-4 py-3 rounded-b-lg border-t border-gray-300 flex items-center justify-center">
                  <p className="text-sm text-gray-800 font-semibold">{`Explore ${item.title}`}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="px-[30px] py-[20px]">
              <div className="flex items-center gap-2">
                <img
                  src={selectedIntegration.icon}
                  alt={selectedIntegration.title}
                  className="w-6 h-auto"
                />
                <p className="text-[16px] font-medium text-[#0F0D1C]">
                  {selectedIntegration.title}
                </p>
              </div>
              <div className="my-2">
                <p className="text-[13px] font-normal text-[#7E889F] mt-1">
                  {selectedIntegration.description}
                </p>
              </div>
            </div>
            <Divider />
            <div className="pt-4 max-h-[420px] overflow-y-auto custom-scrollbar">
              <AllEnrichments
                isForMarketplace
                providerName={selectedIntegration.providerName}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MainContent;
