import {
  Box,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { SiSendinblue } from "react-icons/si";

import GPT from "@/assets/svgs/enrichment-gpt.svg";
import leadService from "@/services/leads.service";

import useTypedText from "@/components/AIAssistant/useTypedText";
import { useSearchLeadsStore } from "../store";
import Conversations from "@/components/AIAssistant/Conversations";
import Icons from "@/components/Icons";
import { userStore } from "@/stores/user.store";

const AIAssistant = ({ onClose, autoFocus = false }: any) => {
  const toast = useToast();
  const { isOpen, onClose: onCloseModel } = useDisclosure();
  // const [inputValue, setInputValue] = useState("");
  const inputValue = useSearchLeadsStore((state) => state.assistantInput);
  const updateState = useSearchLeadsStore((state) => state.updateState);
  const setInputValue = (input: string) => {
    updateState({ assistantInput: input });
  };
  const searchingFor = useSearchLeadsStore((state) => state.searchingFor);
  const isUseMixRank = userStore((state) => state.isUseMixrank);
  const sidebarActiveTab = useSearchLeadsStore(
    (state) => state.sidebarActiveTab,
  );
  const placeholders = [
    "Individuals who are Marketing Managers employed by Amazon.",
    "Individuals who are Data Scientists at Netflix.",
    "Employees who are HR Managers within Airbnb.",
    "Employees who are Product Managers working at Tesla.",
    "Individuals who are Software Engineers employed by IBM.",
    "People who are Directors of Operations working at Salesforce.",
  ];
  const dynamicPlaceholder = useTypedText(placeholders);
  const updateLeadState = useSearchLeadsStore((state) => state.updateState);
  const {
    mutateAsync: searchPeopleUsingGPTMutateAsync,
    isPending,
    data: answerData,
  } = useMutation({
    mutationFn: (input: string) =>
      leadService.searchPeopleUsingGPT({ prompt: input, isUseMixRank }),

    onSuccess: (response, input) => {
      if (response.type === "PEOPLE_SEARCH") {
        if (response.data?.peopleSearchPayload) {
          updateLeadState({
            peopleSearchPayload: response.data?.peopleSearchPayload,
          });
        }
        onClose?.();
        return;
      } else {
        updateLeadState({
          peopleSearchPayload: {
            search_text: input,
          },
        });
        return;
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      updateLeadState({
        searchingFor: "PEOPLE",
      });
      inputValue && searchPeopleUsingGPTMutateAsync(inputValue);
    } else if (event.keyCode === 27) {
      onClose?.();
    }
  };
  const handleChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);
  };
  // !border-[#C03EDC]
  return (
    <Box className="relative flex w-full flex-row items-center gap-4">
      <IconButton
        variant={"ghost"}
        size={"xl"}
        colorScheme="purple"
        // onClick={editDisclosure.onOpen}
        aria-label="Edit"
        icon={<img src={GPT} alt="GPT" />}
      />
      <Textarea
        autoFocus={autoFocus}
        variant={"unstyled"}
        onFocus={(ev) => ev.target.select()}
        w={"full"}
        onKeyDown={handleKeyDown}
        value={inputValue}
        onChange={handleChange}
        placeholder={
          sidebarActiveTab === "company-search"
            ? "Access 200M companies by adding filters and queries on the left pane"
            : dynamicPlaceholder
        }
        rows={1}
        zIndex={"99"}
        isDisabled={sidebarActiveTab !== "people-search"}
        className=" focus-within::!border-[solid_2px_purple] !w-[98%] !resize-none  !border-none !p-2  !pr-[6em]  shadow-none  "
      />
      <ButtonGroup isAttached className="absolute right-8 " zIndex={"999"}>
        {/* send button */}
        <IconButton
          isLoading={searchingFor === "PEOPLE" || isPending}
          isDisabled={!inputValue}
          aria-label="send"
          onClick={() =>
            inputValue && searchPeopleUsingGPTMutateAsync(inputValue)
          }
          colorScheme="purple"
          icon={<SiSendinblue />}
        />
        <IconButton
          variant={"ghost"}
          aria-label="close"
          onClick={onClose}
          icon={<Icons.CloseIcon />}
        />
      </ButtonGroup>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onCloseModel}
        size="xl"
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay
          bg="rgba(0, 0, 0, 0.1)"
          backdropFilter="revert"
          backdropInvert="80%"
          backdropBlur="2px"
        />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Conversations answerData={answerData?.data} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AIAssistant;
